import React from "react";
import { useRef } from 'react';

import { ResponsivePie } from '@nivo/pie'
import { ResponsiveRadialBar } from '@nivo/radial-bar'
import {  Outlet, useNavigate, useParams } from "react-router-dom";
import { 
    Stack, Box, Divider, Typography, 
    Tooltip, tooltipClasses,
    List, ListItem, ListItemText, ListItemAvatar,
    CircularProgress,
    Pagination, Button,
    TextField, InputAdornment, IconButton, Badge, Collapse,
    Autocomplete,
    Popper,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import { HexGrid, Layout, Hexagon, GridGenerator, Text } from 'react-hexgrid';

import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { 
    Search as SearchIcon,
    FilterAltOutlined as FilterAltOutlinedIcon,
} from "@mui/icons-material";

import styles from './Repositories.module.css';

import {
    okColor, warnColor, dangerColor
} from '../../components/constants/Constants';
import { RepositoryDetails } from "../repositoryDetails/RepositoryDetails";
import { RepositoryChips } from "../../components/repositoryChips/RepositoryChips";
import { RepositoryScore } from "../../components/repositoryScore/RepositoryScore";

import { init as initAction, 
    selectRepositories, 
    selectView as selectViewAction, 
    toggleShowFilter,
    setFilter,
} from "./repositoriesSlice";
import { useDispatch, useSelector } from "react-redux";

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 400,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const colorMap = {
    "Not configured": dangerColor,
    "Inadequate": warnColor,
    "Good posture": okColor,
}

const legends = [
    {
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 56,
        itemsSpacing: 0,
        itemWidth: 150,
        itemHeight: 18,
        itemTextColor: '#999',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 12,
        symbolShape: 'square',
        effects: [
            {
                on: 'hover',
                style: {
                    itemTextColor: '#000'
                }
            }
        ]
    }
]

const RepositoryPieChart = React.memo(((props) => {
    return (
            <ResponsivePie
                data={props.data}
                legends={legends}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                cornerRadius={0}
                innerRadius={0.6}
                startAngle={-135}
                endAngle={135}
                padAngle={0.7}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                colors={({_id, data}) => data["color"]}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'brighter',
                            5
                        ]
                    ]
                }}
                enableArcLinkLabels={false}
            />
        )
}),(prevProps, nextProps) => {
    const flag = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
    return flag
})

function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function Repositories() {
    const firstListItem = useRef(null);
    const gridContainer = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { org, integrationSlug }  = useParams();
    const { 
        view, 
        searchLoading, 
        pageLoading,
        detailsFetchStatus,
        details,
        gridDetails,
        searchTerm: searchTermInStore,
        page: pageInStore,
        showFilter,
        filters,
    } = useSelector(selectRepositories);

    const repositories = details[integrationSlug]?.data || [];
    const actions = details[integrationSlug]?.actions || [];
    const distributions = details[integrationSlug]?.distributions || [];
    const total = details[integrationSlug]?.totalCount || 0;
    const selectedAction = filters.selectedAction;
    const perPage = 10;
    
    const gridRepositories = gridDetails[integrationSlug]?.data || [];
    const gridTotal = gridDetails[integrationSlug]?.totalCount || 0;
    const columns = Math.ceil(Math.sqrt(gridTotal)) < 22 ? 22 : Math.ceil(Math.sqrt(gridTotal));
    const rows = Math.ceil(gridTotal/columns);

    const hexagons = GridGenerator.rectangle(columns, rows);

    for (let i = 0; i < gridTotal; i++) {
        const score = gridRepositories[i].score;
        hexagons[i]["score"] =  score;
        hexagons[i]["name"] = gridRepositories[i].name ;
        if (score >= 80) hexagons[i]["class"] = styles.hexagonOk;
        else if (score >= 50) hexagons[i]["class"] = styles.hexagonWarn;
        else hexagons[i]["class"] = styles.hexagonDanger;
    }

    const repositoriesDistribution = distributions.find(d => d.label === "Repositories") || {}

    let pieData = []
    repositoriesDistribution.critical > 0 && pieData.push({
        "id": "Need attention",
        "label": "Need attention",
        "value": repositoriesDistribution.critical,
        "color": dangerColor,
    })
    repositoriesDistribution.warning > 0 && pieData.push({
        "id": "Inadequate controls",
        "label": "Inadequate controls",
        "value": repositoriesDistribution.warning,
        "color": warnColor,
    })
    repositoriesDistribution.ok > 0 && pieData.push({
        "id": "All good here",
        "label": "All good here",
        "value": repositoriesDistribution.ok,
        "color": okColor,
    })

    const radialFields = [
        "Actions", 
        "BranchProtections",
        "CodeOwners",
        "PreCommitHooks",
        "PRProtections",
        "Alerts",
    ]
    const radialChartData = [];
    radialFields.forEach((radialField) => {
        const distribution = distributions.find(d => d.label === radialField) || {}
        const radialData = distribution.label && {
            "id": radialField,
            "data": [
                {
                    "x": "Not configured",
                    "y": distribution.critical
                },
                {
                    "x": "Inadequate",
                    "y": distribution.warning
                },
                {
                    "x": "Good posture",
                    "y": distribution.ok
                }
            ]
        }
        radialData && radialChartData.push(radialData);
    })

    const basePath = `/${org}/github/${integrationSlug}/repositories`;

    const setView = (view) => {
        dispatch(selectViewAction(view))
        setTimeout(() => {
            if(view === 'list') {
                firstListItem.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
            if(view === 'grid') {
                gridContainer.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }, 100)
    };

    const [open, setOpen] = React.useState(false);

    const [selectedGrid, setSelectedGrid] = React.useState(undefined);

    const [searchTermAndPage, setSearchTermAndPage] = React.useState({searchTerm: searchTermInStore, page: pageInStore});
    const [searchTermValue, setSearchTermValue] = React.useState(searchTermInStore);

    const { page, searchTerm } = searchTermAndPage;

    const toggleFilters = () => {
        if(!showFilter) {
            firstListItem.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        dispatch(toggleShowFilter())
    }

    const setPage = (page) => {
        setSearchTermAndPage({...searchTermAndPage, page: page});
        setTimeout(() => {
            firstListItem.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 100)
    }

    const setSearchTerm = (searchTerm) => {
        setSearchTermAndPage({page: 1, searchTerm: searchTerm});
    }

    const debounceSetSearchTerm = debounce((searchTerm) => {
        setSearchTerm(searchTerm);
    }, 200)

    const debouncedSetSearchTermAndPage = (searchTerm) => {
        setSearchTermValue(searchTerm);
        debounceSetSearchTerm(searchTerm)
    }
        

    React.useEffect(() => {
        dispatch(initAction({integrationSlug, page, searchTerm, view}));
    }, [ integrationSlug, page, searchTerm, view ]);

    return (
        <div style={{width: '100%', boxSizing: 'border-box', paddingBottom: '20px'}}>
            <Outlet />
            <Stack direction="column" 
                sx={{position: 'sticky', top: '60px', background: '#FFFFFF', zIndex: 100, mb: 5, pt: '20px'}}>
                <Typography variant='h5'>Current Posture</Typography>
                <Divider />
            </Stack>
            <Stack direction="row" spacing={2}>
                <Box className={styles.root}>
                    {
                        pieData
                        &&
                        <RepositoryPieChart data={pieData} />
                    }
                    <div className={styles.overlay}>
                        <span>Overall Posture</span>
                    </div>
                </Box>
                <Box className={styles.root}>
                    <ResponsiveRadialBar
                        data={radialChartData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        cornerRadius={0}
                        legends={legends}
                        valueFormat=">-.2f"
                        padding={0.4}
                        radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
                        circularAxisOuter={{ 
                            tickSize: 5, 
                            tickPadding: 12,
                            tickRotation: 0, 
                            format: (label) => `${label}%`
                        }}
                        colors={({_id, data}) => colorMap[data["x"]]}
                    />
                    <div className={styles.overlay} style={{top: '-40px'}}>
                        <span>Posture by<br/> Topic</span>
                    </div>
                </Box>
            </Stack>
            <Stack 
                sx={{position: 'sticky', top: '60px', background: '#FFFFFF', zIndex: 100, pt: '20px'}}>
                <Stack direction="row" 
                    justifyContent="space-between">
                    <Typography variant='h5'>Repositories</Typography>
                        {
                            view != "list" &&
                            <Button onClick={() => setView("list")} size={"small"} >
                                <ViewListIcon sx={{mr: '5px'}} /> Switch to List View
                            </Button>
                        }
                        {
                            view != "grid" &&
                            <Button onClick={() => setView("grid")} size={"small"}>
                                <ViewModuleIcon sx={{mr: '5px'}} /> Switch to Grid View
                            </Button>
                        }
                </Stack>
                <Divider sx={{mb: 0}}/>
                {
                    view === 'list' 
                    && 
                    <Stack sx={{mt: 1, px: 1}} direction="row" justifyContent="space-between" alignItems="baseline">
                        <Stack direction="row" justifyContent="start">
                            <TextField
                                label="Filter by repository name"
                                variant="standard"
                                size="small"          
                                sx={{ width: '400px'}}
                                value={searchTermValue}
                                autocomplete="off"
                                onChange={(e) => debouncedSetSearchTermAndPage(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <React.Fragment>
                                            {
                                                searchLoading && detailsFetchStatus === 'loading'
                                                && 
                                                <InputAdornment position="end">
                                                    <CircularProgress color="inherit" size={20} />
                                                </InputAdornment>
                                            }
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <IconButton onClick={toggleFilters} sx={{height: 40, width:40, top: 10, left: 10}} >
                                <Badge overlap="circular" color="info" variant="dot" invisible={false}>
                                    <FilterAltOutlinedIcon />
                                </Badge>
                            </IconButton>
                        </Stack>
                        {
                            total > perPage && !(searchLoading && detailsFetchStatus === 'loading')
                            && 
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                                {
                                    detailsFetchStatus === 'loading'
                                    && 
                                    pageLoading
                                    &&
                                    <div style={{height: '32px', translate: '0 6px', padding: '0 10px'}}>
                                        <CircularProgress color="inherit" size={20}/>
                                    </div>
                                }
                                <Typography variant='body2'>
                                    {(page-1)*perPage+1}-{page*perPage > total ? total : page*perPage} of {total}
                                </Typography>
                                <Pagination count={Math.ceil(total/perPage)}
                                    onChange={(e, page) => setPage(page)}
                                    page={page}
                                    boundaryCount={1}
                                    siblingCount={1} 
                                    variant="outlined" 
                                    shape="rounded" />
                            </Stack>
                        }
                    </Stack>
                }  
                <Collapse sx={{mt: 1, mx: 1, bgcolor: 'rgb(240 248 255)', borderRadius: 1}} 
                    collapsedSize={0}
                    in={showFilter && view == 'list'}>
                    <Stack sx={{p: 1}} className={styles.filtersContainer} direction="row" justifyContent="start" alignItems="center" spacing={1}>
                        <Autocomplete
                            size="small"
                            id="select-action"
                            options={actions}
                            defaultValue={selectedAction}
                            onChange={(e, action) => {
                                dispatch(setFilter({key: "selectedAction", value: action, integrationSlug}))
                            }}
                            groupBy={(action) => action.type}
                            getOptionLabel={(action) => action.name}
                            PopperComponent={({children, ...props}) => {
                                return (
                                    <Popper {...props} placement="bottom-start" style={{width: '300px'}}>
                                        {children}
                                    </Popper>
                                )
                            }}
                            renderOption={(props, action) => {
                                return (
                                    <li {...props} sx={{width: '300px'}}>
                                        <Stack direction="row" 
                                            sx={{width: '300px'}}
                                            justifyContent="space-between" spacing={1}>
                                            <Typography>
                                                {action.name}
                                            </Typography>
                                            <Typography>
                                                [{action.count}]
                                            </Typography>
                                        </Stack>
                                    </li>
                                )
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => {
                                return <TextField {...params} sx={{width: '200px'}} 
                                    variant="standard" 
                                    label="Filter by Action" />
                            }}
                        />
                    </Stack>
                </Collapse>
            </Stack>
            {
                view === 'list' 
                && 
                <Stack sx={{padding: 1}}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {
                            repositories.map((repository, i) => {
                                const refProp = i === 0 
                                    ? {
                                        ref: firstListItem,
                                        sx: {
                                            scrollMarginTop: showFilter ? '240px' : '190px'
                                        }
                                    } 
                                    : {};
                                return <React.Fragment>
                                    <ListItem {...refProp} disableGutters alignItems="flex-start">
                                        <ListItemAvatar>
                                            <RepositoryScore value={repository.score} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Typography color="primary"
                                                    onClick={() => navigate(`${basePath}/${repository.slug}`)}
                                                    sx={{cursor: 'pointer', '&:hover': {textDecoration: 'underline'}}}
                                                    variant="body1">
                                                    {repository.name}
                                                </Typography>
                                            }
                                            secondary={
                                                <RepositoryChips summary={repository.posture_summary} />
                                            }
                                        />
                                    </ListItem>
                                    {i+1  < perPage && <Divider component="li" />}
                                </React.Fragment>
                            })
                        }
                    </List>
                </Stack>
            }
            {
                view === 'grid' 
                &&
                <HtmlTooltip open={open} followCursor
                    title={
                        selectedGrid && <Stack  direction="row" sx={{p: 0.2}} className={styles.hexagonTooltip}> 
                            <RepositoryScore value={selectedGrid.score} />
                            <Typography my={0.25} mx={0.5}>
                                {selectedGrid.name}
                            </Typography>
                        </Stack>
                    }
                >
                    <div className={styles.hexagonGridContainer} ref={gridContainer} >
                        <div style={{ width: `${41.57*1.05*(columns+0.5)+10}px`}}>
                            <HexGrid width={`${41.57*1.05*(columns+0.5)+10}px`} 
                                height={`${(36*1.05*rows)+13}px`} 
                                viewBox={`0 0 ${41.57*1.05*(columns+0.5)+10} ${(36*1.05*rows)+13}`}>
                                <Layout size={{ x: 24, y: 24 }} 
                                    origin={{ x: 24, y: 24 }} 
                                    flat={false} spacing={1.05}>
                                    { 
                                        hexagons.map((hex, i) => 
                                            {
                                                return i < gridTotal &&
                                                <Hexagon className={`${styles.hexagon} ${hex.class}`} 
                                                    key={i} q={hex.q} r={hex.r} s={hex.s}
                                                    onClick={() => navigate(`${basePath}/${gridRepositories[i].slug}`)}
                                                    onMouseEnter={() => {setSelectedGrid(hex); setOpen(true)}}
                                                    onMouseLeave={() => {setSelectedGrid(undefined); setOpen(false)}}>
                                                    <Text className={styles.hexagonText}>
                                                        {(hex.score/10).toFixed(1)}
                                                    </Text>
                                                </Hexagon>
                                            }) 
                                    }
                                </Layout>
                            </HexGrid>
                        </div>
                    
                    </div>
                </HtmlTooltip>
            }  
        </div>
    );
}