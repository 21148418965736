import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDashboard, initAction, selectIntegration } from './dashboardSlice';

import { ConnectGithub } from '../../components/connectGithub/ConnectGithub';

import { IntegrationDetails } from '../integrationDetails/IntegrationDetails';

import styles from './Dashboard.module.css';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { selectSession } from '../session/sessionSlice';

export function Dashboard() {
    const { org, integrationSlug } = useParams();
    const dispatch = useDispatch();
    const dashboard = useSelector(selectDashboard);
    const { loggedInUserOrganization  } = useSelector(selectSession);

    const loggedInUserOrgSlug = loggedInUserOrganization?.slug;

    // eslint-disable-next-line
    useEffect(() => {dispatch(initAction())}, []);
    useEffect(() => {dispatch(selectIntegration(integrationSlug))}, [integrationSlug]);

    if (dashboard.fetchStatus !== 'idle') {
        return <div>Loading...</div>;
    }

    if (dashboard.fetchError !== undefined) {
        return <div>{dashboard.fetchError}</div>;
    }

    if (loggedInUserOrgSlug  && loggedInUserOrgSlug !== org) {
        return (
            <Navigate to={`/${loggedInUserOrgSlug}`} />
        )
    }

    if (dashboard.integrations?.length === 0) {
        if (integrationSlug !== "new") {
            return (
                <Navigate to={`/${org}/github/new`} />
            )
        }
    }

    if (integrationSlug === "new") {
        return (
            <div className={styles.container}>
                <ConnectGithub />
            </div>
        )
    }

    if (dashboard.integrations?.length > 0) {
        if(!integrationSlug) {
            const slug = dashboard.selectedIntegrationSlug 
                ? dashboard.selectedIntegrationSlug
                : dashboard.integrations[0]?.slug;
            return (
                <Navigate to={`/${org}/github/${slug}/actions`} />
            )
        }
        const integration = dashboard.integrations.find(i => i.slug === integrationSlug);
        if (!integration) {
            return (
                <Navigate to={`/${org}/github/${dashboard.integrations[0]?.slug}/actions`} />
            )
        }
    }

    if(dashboard.selectedIntegrationSlug) {
        return (
            <Outlet />
        );
    }
}