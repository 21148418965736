/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Bolt
*/

import React, { useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import  styles from './Bolt.module.css';

import { 
    selectBolt, 
    init as initAction, 
    selectOrg as selectOrgAction,
    boltRepo as boltRepoAction
} from './boltSlice';

import { 
    Stack,
    Typography,
    Divider,
    Button,
} from '@mui/material';

import {
    Verified as VerifiedIcon
 } from '@mui/icons-material';


export function Bolt(props) {
    const params = useParams;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { details, authenticated } = useSelector(selectBolt);

    const { orgs, repos, selectedOrg } = details;

    useEffect(() => {
        dispatch(initAction());
    }, []);

    if (!authenticated) {
        return (
            <Button onClick={() => window.location = "https://api-do-blr.koalalab.com/bolt/oauth2"}>
                Continue with Github
            </Button>
        )
    }

    if (orgs.length === 0) {
        return (
            <Typography variant="h3">
                You don't have access to any Github organization.
            </Typography>
        )
    }

    if (selectedOrg === undefined && orgs.length > 0) {
        return (
            <Stack direction="column">
                <Typography variant="h3">
                    Select an organization
                </Typography>
                <Divider />
                <Stack direction="row">
                    {orgs.map((org) => {
                        return (
                            <Button onClick={() => dispatch(selectOrgAction(org))}>
                                {org.name}
                            </Button>
                        )
                    })}
                </Stack>
            </Stack>
        )
    }
    const orgRepos = repos[selectedOrg?.name] || [];

    if (selectedOrg !== undefined && orgRepos.length === 0) {
        return (
            <Typography variant="h3">
                You don't have any repositories in this organization.
            </Typography>
        )
    }

    if (orgRepos.length > 0) {
        return (
            <Stack direction="column">
                <Typography variant="h3">
                    Bolt Feature
                </Typography>
                <Divider />
                <Stack direction="column">
                    {orgRepos.map((repo) => {
                        return (
                            <Stack direction="row">
                                <Typography>
                                    {repo.name}
                                </Typography>
                                <Button onClick={() => dispatch(boltRepoAction({orgName: selectedOrg.name, repoName: repo.name}))}>
                                    Bolt
                                </Button>
                            </Stack>
                        )
                    })}
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack direction="column">
            <Typography variant="h3">
                Bolt Feature
            </Typography>
            <Divider />
        </Stack>
    )
}
