import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSessionData, login, logout, signup } from './sessionAPI';

const initialState = {
    loggedInUser: undefined,
    loggedInUserOrganization: undefined,
    webauthnRegistered: false,
    
    fetchStatus: 'idle',
    fetchError:  undefined,
    fetchedOnce: false,

    logoutStatus: 'idle',
    logoutError: undefined,

    // loginStatus: 'idle',
    // loginError: undefined,

    signupStatus: 'idle',
    signupError: undefined,
};

export const fetchSessionDataAsync = createAsyncThunk(
  'session/fetchSessionData',
  async () => {
    const response = await fetchSessionData();
    return response.data;
  }
);

export const signupAsync = createAsyncThunk(
  'session/singup',
  async (payload) => {
    const response = await signup(payload);
    return  response.data;
  }
);

export const loginAsync = createAsyncThunk(
  'session/login',
  async (payload) => {
    const response = await login(payload);
    return response.data;
  }
)

export const fetchSessionIfLoggedInKeyIsUndefined = () => (dispatch, getState) => {
  const { loggedInUser } = selectSession(getState());
  if (loggedInUser === undefined) {
    dispatch(fetchSessionDataAsync());
  }
};

export const fetchSessionIfNotFetchedOnce = () => (dispatch, getState) => {
  const { fetchedOnce } = selectSession(getState());
  if (!fetchedOnce) {
    dispatch(fetchSessionDataAsync());
  }
};

export const logoutAsync  = createAsyncThunk(
  'session/logout',
  async () => {
    const response = await logout();
    return response.data;
  }
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  // // The `reducers` field lets us define reducers and generate associated actions
  // reducers: {
  //   login: (state, action) => {
  //     state.user = action.payload;
  //   },
  //   logout: (state) => {
  //     state.user = null;
  //   },
  // },
//   The `extraReducers` field lets the slice handle actions defined elsewhere,
//   including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchSessionDataAsync.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(fetchSessionDataAsync.fulfilled, (state, action) => {
        state.loggedInUser = action.payload?.user;
        state.loggedInUserOrganization = action.payload?.organization;
        state.webauthnRegistered = action.payload?.webauthn_registered;
        state.fetchError = undefined;
        state.fetchStatus = 'idle';
        state.fetchedOnce = true;
      })
      .addCase(fetchSessionDataAsync.rejected, (state, action) => {
        state.fetchError = action.error;
        state.fetchStatus = 'idle';
        state.fetchedOnce = true;
      })
      .addCase(logoutAsync.pending, (state) => {
        state.logoutStatus = 'loading';
      })
      .addCase(logoutAsync.fulfilled, (state) =>  {
        state.loggedInUser = undefined;
        state.logoutStatus = 'success';
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.logoutError = action.error;
        state.logoutStatus = 'failed';
      })
      .addCase(signupAsync.pending, (state) => {
        state.signupStatus = 'loading'
      })
      .addCase(signupAsync.fulfilled, (state) => {
        state.signupStatus = 'success';
      })
      .addCase(signupAsync.rejected, (state) => {
        state.signupStatus = 'failed';
      })
      .addCase(loginAsync.pending, (state) => {
        state.loginStatus = 'loading'
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loginStatus = 'success';
        const { redirect } = action.payload;
        window.location = redirect;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loginStatus = 'failed';
        state.loginError  = action.error;
      });
  },
});

// export const { login, logout } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSession = (state) => {
    // console.log(state)
    return state.session;
}

export default sessionSlice.reducer;
