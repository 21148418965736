import {
    initAction,
    fetchIntegrationsAsync,
    selectIntegration,
} from './dashboardSlice';

import {
    reset as resetRepositories,
} from '../repositories/repositoriesSlice';

const createFetchItegrationsListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: initAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchIntegrationsAsync(action.payload));
        },
    });
}

const createSetIntegrationListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: selectIntegration,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(resetRepositories());
        },
    });
}

const listeners = [
    createFetchItegrationsListener,
    createSetIntegrationListener,
]

export default listeners;