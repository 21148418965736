import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showTerminal: false,
    terminalHeight: 0,
    path: undefined,
};


export const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  // // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setShowTerminal: (state, action) => {
      state.showTerminal = action.payload;
    },
    setTerminalHeight: (state, action) => {
        state.terminalHeight = action.payload;
    },
    startTerminalWithPath: (state, action) => {
      state.showTerminal = true;
      state.path = action.payload;
    },
  },
});

export const { 
    setShowTerminal,
    setTerminalHeight,
    startTerminalWithPath,
} = terminalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTerminal = (state) => {
    // console.log(state)
    return state.terminal;
}

export default terminalSlice.reducer;
