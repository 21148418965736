/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/component -o src/components PageWrapper
*/
import React from 'react'

import styles from './RepositoryChips.module.css'

import {
  Stack,
  Chip,
} from '@mui/material';

import {
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Error as DangerousIcon,
  DoNotDisturbOn as DoNotDisturbOnIcon,
} from '@mui/icons-material';

export function RepositoryChips(props) {
  const { summary } = props;
  const chipProps = {
    "sx": {"mt": '10px', 'mr': '10px'},
    "size": "small",
    "variant": "outlined",
  };

  const chips = [
    {
      key: "actions",
      label: "Actions",
    },
    {
      key: "alerts",
      label: "Alerts",
    },
    {
      key: "branch_protections",
      label: "Branch Protection",
    },
    {
      key: "webhooks",
      label: "Webhooks",
    },
    {
      key: "code_owners",
      label: "Code Owners",
    },
    {
      key: "pr_protections",
      label: "PR Protection",
    },
    {
      key: "pre_commit_hooks",
      label: "Pre-Commit Hooks",
    },
    {
      key: "tag_protections",
      label: "Tag Protection",
    },
  ]

  const getIcon = (posture) => {
    if (!posture) return <DoNotDisturbOnIcon color="disabled" />;
    if (posture == "poor" ) return <DangerousIcon color="error" />;
    if (posture == "inadequate" ) return <WarningIcon color="warning" />;
    if (posture == "good" ) return <CheckCircleIcon color="success" />;
  }
  
  return (
    <Stack sx={{mt: '10px', ...props.sx}} direction="row" alignItems={"baseline"} flexWrap="wrap">
      {
        chips.map((chip) => {
          return (
            <Chip {...chipProps} label={chip.label} icon={getIcon(summary[chip.key])} />
          )
        })
      }
    </Stack>
  )
}