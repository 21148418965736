/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/component -o src/components PageWrapper
*/
import React from 'react'

import {
  Stack, Typography, Divider, Collapse, Alert, AlertTitle
} from '@mui/material'

import {
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material'

import styles from './CollapsibleContainer.module.css'

export function CollapsibleContainer(props) {
  const { children, title } = props;
  const initOpen = props.initOpen  === undefined ? true : props.initOpen;
  const [openFlag, setOpenFlag] = React.useState(initOpen);
  return (
    <Stack direction="column" sx={{ mt: 1 }}>
        <Stack direction="column" className={styles.header}>
          <Stack onClick={() => setOpenFlag(!openFlag)} 
              sx={{ py: 0.5}}
              direction="row" 
              justifyContent="space-between">
              <Typography variant="body1" sx={{textTransform: 'uppercase'}}>
                  {title}
              </Typography>
              <KeyboardArrowDownIcon 
                  className={`${styles.arrow} ${openFlag ? styles.open : styles.close}`}
              />
          </Stack>
          <Divider />
        </Stack>
        <Collapse in={openFlag}>
            {children}
        </Collapse>
    </Stack>
  )
}