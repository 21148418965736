import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSession } from "../session/sessionSlice";

export function NotFound() {
    const navigate = useNavigate();
    const session = useSelector(selectSession);

    const { loggedInUserOrganization } = session;
    const slug = loggedInUserOrganization?.slug;
    const waitSeconds = 1;
    const [seconds, setSeconds] = React.useState(waitSeconds);

    if(slug) {
        setTimeout(() => {
            navigate(`/${slug}`);
        }, waitSeconds * 1000);
    } else {
        return (
            <div>
                <h1>Error</h1>
                <p>User/Organization not found or is inactive</p>
            </div>
        )
    }
    setInterval(() => {
        setSeconds(seconds - 1);
    }, 1000);
    return (
        <div>
            <h1>404</h1>
            <p>Page not found</p>
            <p>Redirecting to Home in {seconds} seconds...</p>
        </div>
    );
}