import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogoImg from "./logo.png";
import LoginIcon from '@mui/icons-material/Login';
import Button from '@mui/material/Button';

        
import styles from './login.module.css';
import { useSearchParams, Navigate, Link as RouterLink } from 'react-router-dom';
import {
    fetchSessionIfNotFetchedOnce,
    selectSession,
    signupAsync, 
    loginAsync,
} from '../session/sessionSlice';
import { Box, Stack, TextField, Link, Typography } from '@mui/material';

const LoginForm  = () => {
    return (
        <React.Fragment>
            <TextField
                error={false}
                name="email"
                label="Email address"
                defaultValue=""
                required
                type="email"
                helperText=""
                variant="standard"/>
            <TextField
                type='password'
                name="password"
                error={false}
                required
                label="Password"
                defaultValue=""
                helperText="&nbsp;"
                variant="standard"/>
            <Button className={styles.loginButton} 
                type='submit'
                variant="contained" startIcon={<LoginIcon />}>
                Login
            </Button>
            <Stack direction={"row"} sx={{alignItems: 'center', justifyContent: 'center', m:1 }}>
                <Typography color='inherit' sx={{mx: 1}}>
                    "Don't have an account?"
                </Typography>

                <Link component={RouterLink}
                    underline='none'
                    to={"/signup"}>
                    <b>Sign up</b>
                </Link>
            </Stack>
        </React.Fragment>
    )
}

const SignupForm  = () => {
    return (
        <React.Fragment>
            <TextField
                error={false}
                label="Name"
                name="name"
                required
                defaultValue=""
                helperText=""
                variant="standard"/>
            <TextField
                error={false}
                label="Email address"
                name="email"
                type="email"
                required
                defaultValue=""
                helperText=""
                variant="standard"/>
            <TextField
                error={false}
                label="Name of organisation"
                name="org"
                required
                defaultValue=""
                helperText=""
                variant="standard"/>
            <TextField
                type='password'
                error={false}
                label="Password"
                name="password"
                required
                defaultValue=""
                helperText="&nbsp;"
                variant="standard"/>
            <Button className={styles.loginButton} 
                type='submit'
                variant="contained" startIcon={<LoginIcon />}>
                Sign up
            </Button>
            <Stack direction={"row"} sx={{alignItems: 'center', justifyContent: 'center', m:1 }}>
                <Typography color='inherit' sx={{mx: 1}}>
                    Already have an account?
                </Typography>
                <Link component={RouterLink}
                    underline='none'
                    to={"/login"}>
                    <b>Login</b>
                </Link>
            </Stack>
        </React.Fragment>
    )
}

export function Login(props) {
    const dispatch = useDispatch();
    const [searchParams, _] = useSearchParams();
    const { loggedInUser, loggedInUserOrganization } = useSelector(selectSession);

    const { mode } = props;
    useEffect(() => {dispatch(fetchSessionIfNotFetchedOnce())}, [])

    const redirectTo = searchParams.get("redirect_to")
    const URLEncodedRedirectTo = encodeURIComponent(redirectTo)
    
    if (loggedInUser && loggedInUserOrganization) {
        if(redirectTo) {
            return (
                <Navigate to={redirectTo} />
            )
        }
        return (
            <Navigate to={`/${loggedInUserOrganization.slug}`} />
        )
    }

    const onSignupSubmit = (e) => {
        const userName = e.target.name.value;
        const userEmail = e.target.email.value;
        const orgName = e.target.org.value;
        const password = e.target.password.value;
        const signupData = {
            userName,
            userEmail,
            orgName,
            password,
        }
        e.preventDefault()
        dispatch(signupAsync(signupData))
    }

    const onLoginSubmit = (e) => {
        const userEmail = e.target.email.value;
        const password = e.target.password.value;
        
        const loginData = {
            userEmail,
            password,
        }
        e.preventDefault()
        dispatch(loginAsync(loginData))

    }

    return (
        <Stack direction={"row"} className={styles.container}>
            <Stack className={styles.formContainer}>
                <span className={styles.logoImgContainer}><img className={styles.logoImg} alt='logo' src={LogoImg} /></span>
                <span className={styles.headerText}>Welcome to KoalaLab</span>
                <span className={styles.subHeaderText}>X-Ray for Modern CI/CD</span>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '325px'},
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onSubmit={mode == "login" ? onLoginSubmit : onSignupSubmit}                    
                >
                    <Stack direction={"column"} sx={{alignItems: 'center'}}>
                        {
                            mode == "login"
                            ? <LoginForm URLEncodedRedirectTo={URLEncodedRedirectTo} />
                            : <SignupForm />
                        }
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    )
}
