import React from 'react';
import { Xterm } from './xterm';

import 'xterm/css/xterm.css';
import { Modal } from './modal';

export default class Terminal extends React.Component {
    constructor(props) {
        super();
        this.xterm = new Xterm(props, this.showModal);
        this.showModal =  this.showModal.bind(this);
        this.sendFile = this.sendFile.bind(this);
        this.props = props;
    }

    async componentDidMount() {
        await this.xterm.refreshToken();
        this.xterm.open(this.container);
        this.xterm.connect();
    }

    componentWillUnmount() {
        this.xterm.dispose();
    }

    render(p, q) {
        const { id, modal } = this.props;
        // const { modal } = q;
        return (
            <div id={id} ref={c => (this.container = c)} style={{height: 'calc(100% - 30px)', padding: '25px 5px 5px 5px'}}>
                <Modal show={modal}>
                    <label class="file-label">
                        <input onChange={this.sendFile} class="file-input" type="file" multiple />
                        <span class="file-cta">Choose files…</span>
                    </label>
                </Modal>
            </div>
        );
    }

    showModal() {
        this.setState({ modal: true });
    }

    sendFile(event) {
        this.setState({ modal: false });
        const files = (event.target).files;
        if (files) this.xterm.sendFile(files);
    }
}