/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Actions

    TODO: Add the following lines in src/app/store.js:
    import actionsListenerCreators from '../features/actions/actionsListeners';
    actionsListenerCreators.forEach(listener => listener(listenerMiddleware));
*/

import {
    init as initAction,
    fetchDetailsAsync,
} from './actionsSlice';

const createInitListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: initAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchDetailsAsync(action.payload));
        },
    });
}

const listeners = [
    createInitListener,
]

export default listeners;