import axios from "axios";

export const fetchResourcesData = async (slug) => {
    return await axios
        .get(`resources/${slug}`)
}

export const createAccessRequestForResource = async (params) => {
    const { id } = params;
    return await axios
        .post(`resources/${id}/accessRequests`, params)
}