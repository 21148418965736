/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Repositories
*/

import axios from "axios";

export const fetchDetails = async ({integrationSlug, params}) => {
    return await axios
        .get(`github/integrations/${integrationSlug}/repositories`, { params })
}
