/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Repositories

    TODO:: Add the following lines in src/app/store.js:
    import repositoriesReducer from '../features/repositories/repositoriesSlice';
    reducer['repositories'] = repositoriesReducer;
*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDetails } from './repositoriesAPI';

const initialFilters = {
    selectedAction: undefined,
}

const initialState = {
    page: 1,
    searchTerm: '',
    details: {},
    gridDetails: {},
    detailsFetchStatus: 'idle',
    detailsFetchError: undefined,
    showFilter: false,
    filters: initialFilters,
    view: 'list',
};

export const fetchDetailsAsync = createAsyncThunk(
    'repositories/fetchDetails',
    async (payload, { getState }) => {
        const state = selectRepositories(getState());
        const {filters} = state;
        const selectedAction = filters?.selectedAction?.name;
    
        const page = payload.page || state.page;
        const searchTerm = payload.searchTerm || state.searchTerm;
        const view = payload.view || state.view;

        const params = {
            page,
            q: searchTerm,
            view,
        };

        if(selectedAction) {
            params.selectedAction = selectedAction;
        }

        const response = await fetchDetails({...payload, params});
        return response.data;
    }
);

export const repositoriesSlice = createSlice({
    name: 'repositories',
    initialState,
    reducers: {
        init: (state, action) => {
            const {page, searchTerm, filters} = action.payload;
            state.page = page;
            state.searchTerm = searchTerm;
            if(state.page != page) {
                state.pageLoading = true;
                state.searchLoading = false;
            } else if(state.searchTerm != searchTerm) {
                state.pageLoading = false;
                state.searchLoading = true;
            }
        },
        reset: (state, action) => {
            state.page = 1;
            state.searchTerm = '';
            state.pageLoading = false;
            state.view = 'list';
            state.showFilter = false;
            state.filters = initialFilters;
        },
        setFilter: (state, action) => {
            const { key, value } = action.payload;
            state.filters[key] = value;
            state.page = 1;
        },
        toggleShowFilter: (state, action) => {
            state.showFilter = !state.showFilter;
        },
        selectView: (state, action) => {
            const payload = action.payload;
            state.view = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsAsync.pending, (state) => {
                state.detailsFetchStatus = 'loading';
                state.detailsFetchError = undefined;
            })
            .addCase(fetchDetailsAsync.fulfilled, (state, action) => {
                state.detailsFetchStatus = 'idle';
                const { view } = action.payload;
                if(view === 'grid') {
                    const { data, totalCount, integrationSlug } = action.payload;
                    state.gridDetails[integrationSlug] = {
                        data: data,
                        totalCount: totalCount,
                    };
                } else {
                    const { data, totalCount, integrationSlug, distributions, actions } = action.payload;
                    state.details[integrationSlug] = {
                        data: data,
                        totalCount: totalCount,
                        distributions: distributions,
                        actions: actions,
                    };
                }
            })
            .addCase(fetchDetailsAsync.rejected, (state, action) => {
                state.detailsFetchStatus = 'idle';
                state.detailsFetchError = action.error.message;
            })
    }
});

export const { init, reset, selectView, toggleShowFilter, setFilter } = repositoriesSlice.actions;

export const selectRepositories = (state) => state.repositories;

export default repositoriesSlice.reducer;


