import { selectTab as selectTabAction, fetchResourcesAsync } from "./resourcesSlice";

const createFetchResourcesListener = (listenerMiddleware) => {
  listenerMiddleware.startListening({
    actionCreator: selectTabAction,
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(fetchResourcesAsync(action));
    },
  });
}

const listeners = [
  createFetchResourcesListener,
]

export default listeners;