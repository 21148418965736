import React, { useEffect } from 'react';

import ReactTimeAgo from 'react-time-ago'

import  styles from './IntegrationDetails.module.css';
import { useParams, Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { selectIntegrationDetails, selectTab } from './integrationDetailsSlice';
import { VerticalTabs } from '../../components/verticalTabs/VerticalTabs';

import { Card, Stack,
    Typography,
    CardContent,
    CardActions,
    Button,
    Box,
    Grid,
    Divider, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Chip,
} from '@mui/material';
import { StyledTableRow } from '../../components/styledTableRow/StyledTableRow';

import VerifiedIcon from '@mui/icons-material/Verified';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ErrorIcon from '@mui/icons-material/Error';
import { Issues } from './Issues';


const RowComponentLayout = ({children, issue}) => {
    return (
        <TableRow key={issue.id} sx={
            {
                '& > td:last-of-type': {textAlign: 'right'},
                '&:last-of-type td': {border: 'none'},
            }}>
            {children}
        </TableRow>
    )
}


const DeployKeyIssueRowComponent = ({issue}) => {
    return (
        <RowComponentLayout issue={issue}>
            <TableCell >
                    <Typography>Repo: {issue.repository.name}</Typography>
            </TableCell>
            <TableCell>
                <Table size="small" >
                    <TableHead>
                        <TableRow>
                            <TableCell >
                                <Typography>Key Name</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>Added By</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>Created</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>Last Used</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>Read Only</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            issue.keys?.map((key) => {
                                return (
                                    <StyledTableRow key={key.id} sx={{height: '10px'}}>
                                        <TableCell >
                                            <Typography>{key.name}</Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{key.addedBy}</Typography>
                                        </TableCell>
                                        <TableCell >
                                        <Typography>
                                                <ReactTimeAgo date={key.createdAt} locale="en-US"/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>
                                                {
                                                    key.lastUsedAt 
                                                    ? <ReactTimeAgo date={key.lastUsedAt} locale="en-US"/>
                                                    : "Never"
                                                }
                                            </Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{key.readOnly ? "Yes" : "No"}</Typography>
                                        </TableCell>
                                    </StyledTableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableCell>
            <TableCell >
                <Button variant="text" 
                    size="small" 
                    href={issue.githubLink} 
                    target="_blank"
                    endIcon={<OpenInNewIcon />}
                >
                    View on Github
                </Button>
            </TableCell>
        </RowComponentLayout>
    )
}

const AccessTokenIssueRowComponent = ({issue}) => {
    const accessToken = issue.accessToken;
    return (
        <RowComponentLayout issue={issue}>
            <TableCell >
                <Typography>Created By: {accessToken.createdBy}</Typography>
            </TableCell>
            <TableCell >
                <Typography>
                    Created: <ReactTimeAgo date={accessToken.createdAt} locale="en-US"/>
                </Typography>
            </TableCell>
            <TableCell >
                <Typography>
                    Last Used: 
                    {
                        accessToken.lastUsedAt 
                        ? <ReactTimeAgo date={accessToken.lastUsedAt} locale="en-US"/>
                        : "Never"
                    }
                </Typography>
            </TableCell>
            <TableCell >
                <Button variant="text"
                    size="small"
                    href={accessToken.githubLink}
                    target="_blank"
                    endIcon={<OpenInNewIcon />}
                >
                    View on Github
                </Button>
            </TableCell>
        </RowComponentLayout>
    )
}

const ActionFileIssueRowComponent = ({issue}) => {
    const actionFile = issue.action;
    return (
        <RowComponentLayout issue={issue}>
            <TableCell >
                <Typography>Repo: {issue?.repository?.name}</Typography>
            </TableCell>
            <TableCell >
                <Typography>File: {actionFile.name}</Typography>
            </TableCell>
            <TableCell >
                <Button variant="text"
                    size="small"
                    href={issue.githubLink}
                    target="_blank"
                    endIcon={<OpenInNewIcon />}
                >
                    View on Github
                </Button>
            </TableCell>
        </RowComponentLayout>
    )
}

export const RowComponents = {
    "KoalaLab/Github/DeployKeyIssue/StaleKey": DeployKeyIssueRowComponent,
    "KoalaLab/Github/DeployKeyIssue/GhostUserKey" :  DeployKeyIssueRowComponent,
    "KoalaLab/Github/DeployKeyIssue/ReadWriteKey": DeployKeyIssueRowComponent,
    "KoalaLab/Github/AccessTokenIssue/ExcessivePermission": AccessTokenIssueRowComponent,
    "KoalaLab/Github/ActionIssue/ThirdPartyActionWithoutHash": ActionFileIssueRowComponent,
    "KoalaLab/Github/ActionIssue/UnsecureCommandsAllowed": ActionFileIssueRowComponent,
    "KoalaLab/Github/ActionIssue/ActionHasPullRequestTargetEventTrigger": ActionFileIssueRowComponent,
    "KoalaLab/Github/ActionIssue/DangerousPermissions": ActionFileIssueRowComponent,
    "KoalaLab/Github/ActionIssue/ExpressionInjections": ActionFileIssueRowComponent,
    "KoalaLab/Github/ActionIssue/CreateOrApprovePR": ActionFileIssueRowComponent,

}