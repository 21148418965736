/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Bolt
*/

import axios from "axios";

export const fetchDetails = async (payload) => {
    return await axios
        .get(`/bolt/orgs`)
}

export const fetchRepos = async ({name: orgName}) => {
    return await axios
        .get(`/bolt/orgs/${orgName}/repos`)
}

export const boltRepo = async (payload) => {
    console.log(payload)
    const {orgName, repoName} = payload;
    return await axios
        .post(`/bolt/orgs/${orgName}/repos/${repoName}/bolt`)
}