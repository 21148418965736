import { Terminal } from 'xterm';
import { CanvasAddon } from 'xterm-addon-canvas';
import { WebglAddon } from 'xterm-addon-webgl';
import { FitAddon } from 'xterm-addon-fit';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { ImageAddon } from 'xterm-addon-image';
import { OverlayAddon } from './addons/overlay';
import { ZmodemAddon } from './addons/zmodem';

import 'xterm/css/xterm.css';

// interface TtydTerminal extends Terminal {
//     fit(): void;
// }

// declare global {
//     interface Window {
//         term: TtydTerminal;
//     }
// }

const Command = {
    // server side
    OUTPUT: '0',
    SET_WINDOW_TITLE: '1',
    SET_PREFERENCES: '2',

    // client side
    INPUT: '0',
    RESIZE_TERMINAL: '1',
    PAUSE: '2',
    RESUME: '3',
}
// type Preferences = ITerminalOptions & ClientOptions;

// export type RendererType = 'dom' | 'canvas' | 'webgl';

// export interface ClientOptions {
//     rendererType: RendererType;
//     disableLeaveAlert: boolean;
//     disableResizeOverlay: boolean;
//     enableZmodem: boolean;
//     enableTrzsz: boolean;
//     enableSixel: boolean;
//     titleFixed?: string;
// }

// export interface FlowControl {
//     limit: number;
//     highWater: number;
//     lowWater: number;
// }

// export interface XtermOptions {
//     wsUrl: string;
//     tokenUrl: string;
//     flowControl: FlowControl;
//     clientOptions: ClientOptions;
//     termOptions: ITerminalOptions;
// }

function toDisposable(f) {
    return { dispose: f };
}

function addEventListener(target, type, listener) {
    target.addEventListener(type, listener);
    return toDisposable(() => target.removeEventListener(type, listener));
}

export class Xterm {
    writeFunc = (data) => this.writeData(new Uint8Array(data));

    // constructor(options, sendCb) {}
    constructor(options, sendCb) {
        this.disposables = [];
        this.textEncoder = new TextEncoder();
        this.textDecoder = new TextDecoder();
        this.written = 0;
        this.pending = 0;
    
        this.terminal = undefined;
        this.fitAddon = new FitAddon();
        this.overlayAddon = new OverlayAddon();
        this.webglAddon = undefined;
        this.canvasAddon = undefined;
        this.zmodemAddon = undefined;
    
        this.socket = undefined;
        this.token = undefined;
        this.opened = false;
        this.title = undefined;
        this.titleFixed = undefined;
        this.resizeOverlay = true;
        this.reconnect = true;
        this.doReconnect = true;
        // this.register = this.register.bind(this);
        // this.sendFile = this.sendFile.bind(this);
        // this.refreshToken = this.refreshToken.bind(this);
        // this.onWindowUnload = this.onWindowUnload.bind(this);
        // this.open = this.open.bind(this);
        // this.setRendererType = this.setRendererType.bind(this);
        // this.applyPreferences = this.applyPreferences.bind(this);
        // this.onSocketData = this.onSocketData.bind(this);
        // this.onSocketClose = this.onSocketClose.bind(this);
        // this.connect = this.connect.bind(this);
        // this.sendData = this.sendData.bind(this);
        // this.writeData = this.writeData.bind(this);
        // this.initListeners = this.initListeners.bind(this);
        this.options = options;
    }

    dispose = () => {
        for (const d of this.disposables) {
            d.dispose();
        }
        this.disposables.length = 0;
    }

    register = (d) => {
        this.disposables.push(d);
        return d;
    }

    sendFile = (files) => {
        this.zmodemAddon?.sendFile(files);
    }

    refreshToken = async () => {
        // this.token = "";
        try {
            const resp = await fetch(this.options.tokenUrl);
            if (resp.ok) {
                const json = await resp.json();
                this.token = json.token;
            }
        } catch (e) {
            console.error(`[ttyd] fetch ${this.options.tokenUrl}: `, e);
        }
    }

    onWindowUnload = (event) => {
        event.preventDefault();
        if (this.socket?.readyState === WebSocket.OPEN) {
            const message = 'Close terminal? this will also terminate the command.';
            event.returnValue = message;
            return message;
        }
        return undefined;
    }

    open = (parent) => {
        this.terminal = new Terminal(this.options.termOptions);
        const { terminal, fitAddon, overlayAddon } = this;
        // 
        window.term = terminal;
        window.term.fit = () => {
            this.fitAddon.fit();
        };

        terminal.loadAddon(fitAddon);
        terminal.loadAddon(overlayAddon);
        terminal.loadAddon(new WebLinksAddon());

        terminal.open(parent);
        fitAddon.fit();
    }

    initListeners = () => {
        const { terminal, fitAddon, register, sendData } = this;
        register(
            terminal.onTitleChange(data => {
                if (data && data !== '' && !this.titleFixed) {
                    document.title = data + ' | ' + this.title;
                }
            })
        );
        register(terminal.onData(data => sendData(data)));
        register(terminal.onBinary(data => sendData(Uint8Array.from(data, v => v.charCodeAt(0)))));
        register(
            terminal.onResize(({ cols, rows }) => {
                const msg = JSON.stringify({ columns: cols, rows: rows });
                this.socket?.send(this.textEncoder.encode(Command.RESIZE_TERMINAL + msg));
                // if (this.resizeOverlay) overlayAddon.showOverlay(`${cols}x${rows}`, 300);
            })
        );
        register(
            terminal.onSelectionChange(() => {
                if (this.terminal.getSelection() === '') return;
                try {
                    document.execCommand('copy');
                } catch (e) {
                    return;
                }
                // this.overlayAddon?.showOverlay('\u2702', 200);
            })
        );
        register(addEventListener(window, 'resize', () => fitAddon.fit()));
        register(addEventListener(window, 'beforeunload', this.onWindowUnload));
    }

    writeData = (data) => {
        const { terminal, textEncoder } = this;
        const { limit, highWater, lowWater } = this.options.flowControl;

        this.written += data.length;
        if (this.written > limit) {
            terminal.write(data, () => {
                this.pending = Math.max(this.pending - 1, 0);
                if (this.pending < lowWater) {
                    this.socket?.send(textEncoder.encode(Command.PAUSE));
                }
            });
            this.pending++;
            this.written = 0;
            if (this.pending > highWater) {
                this.socket?.send(textEncoder.encode(Command.RESUME));
            }
        } else {
            terminal.write(data);
        }
    }

    sendData = (data) => {
        const { socket, textEncoder } = this;
        if (socket?.readyState !== WebSocket.OPEN) return;

        if (typeof data === 'string') {
            socket.send(textEncoder.encode(Command.INPUT + data));
        } else {
            const payload = new Uint8Array(data.length + 1);
            payload[0] = Command.INPUT.charCodeAt(0);
            payload.set(data, 1);
            socket.send(payload);
        }
    }

    connect = () => {
        
        this.socket = new WebSocket(this.options.wsUrl, ['tty']);
        const { socket, register } = this;

        socket.binaryType = 'arraybuffer';
        register(addEventListener(socket, 'open', this.onSocketOpen));
        register(addEventListener(socket, 'message', this.onSocketData));
        register(addEventListener(socket, 'close', this.onSocketClose));
        register(addEventListener(socket, 'error', () => (this.doReconnect = false)));
    }

    onSocketOpen = () => {
        console.log('[ttyd] websocket connection opened');

        const { textEncoder, terminal, overlayAddon } = this;
        // 
        const msg = JSON.stringify({ AuthToken: this.token, columns: terminal.cols, rows: terminal.rows });
        this.socket?.send(textEncoder.encode(msg));

        if (this.opened) {
            terminal.reset();
            terminal.options.disableStdin = false;
            overlayAddon.showOverlay('Reconnected', 300);
        } else {
            this.opened = true;
        }

        this.doReconnect = this.reconnect;
        this.initListeners();
        terminal.focus();
    }

    onSocketClose = (event) => {
        console.log(`[ttyd] websocket connection closed with code: ${event.code}`);

        const { refreshToken, connect, doReconnect, overlayAddon } = this;
        overlayAddon.showOverlay('Connection Closed');
        this.dispose();

        // 1000: CLOSE_NORMAL
        if (event.code !== 1000 && doReconnect) {
            overlayAddon.showOverlay('Reconnecting...');
            refreshToken().then(connect);
        } else {
            const { terminal } = this;
            const keyDispose = terminal.onKey(e => {
                const event = e.domEvent;
                if (event.key === 'Enter') {
                    keyDispose.dispose();
                    overlayAddon.showOverlay('Reconnecting...');
                    refreshToken().then(connect);
                }
            });
            overlayAddon.showOverlay('Press ⏎ to Reconnect');
        }
    }

    onSocketData = (event) => {
        const { textDecoder } = this;
        const rawData = event.data;
        const cmd = String.fromCharCode(new Uint8Array(rawData)[0]);
        const data = rawData.slice(1);

        switch (cmd) {
            case Command.OUTPUT:
                this.writeFunc(data);
                break;
            case Command.SET_WINDOW_TITLE:
                this.title = textDecoder.decode(data);
                document.title = this.title;
                break;
            case Command.SET_PREFERENCES:
                this.applyPreferences({
                    ...this.options.clientOptions,
                    ...JSON.parse(textDecoder.decode(data)),
                });
                break;
            default:
                console.warn(`[ttyd] unknown command: ${cmd}`);
                break;
        }
    }

    applyPreferences = (prefs) => {
        const { terminal, fitAddon, register } = this;
        if (prefs.enableZmodem || prefs.enableTrzsz) {
            this.zmodemAddon = new ZmodemAddon({
                zmodem: prefs.enableZmodem,
                trzsz: prefs.enableTrzsz,
                onSend: this.sendCb,
                sender: this.sendData,
                writer: this.writeData,
            });
            this.writeFunc = data => this.zmodemAddon?.consume(data);
            terminal.loadAddon(register(this.zmodemAddon));
        }
        Object.keys(prefs).forEach(key => {
            const value = prefs[key];
            switch (key) {
                case 'rendererType':
                    this.setRendererType(value);
                    break;
                case 'disableLeaveAlert':
                    if (value) {
                        window.removeEventListener('beforeunload', this.onWindowUnload);
                        console.log('[ttyd] Leave site alert disabled');
                    }
                    break;
                case 'disableResizeOverlay':
                    if (value) {
                        console.log('[ttyd] Resize overlay disabled');
                        this.resizeOverlay = false;
                    }
                    break;
                case 'disableReconnect':
                    if (value) {
                        console.log('[ttyd] Reconnect disabled');
                        this.reconnect = false;
                        this.doReconnect = false;
                    }
                    break;
                case 'enableZmodem':
                    if (value) console.log('[ttyd] Zmodem enabled');
                    break;
                case 'enableTrzsz':
                    if (value) console.log('[ttyd] trzsz enabled');
                    break;
                case 'enableSixel':
                    if (value) {
                        terminal.loadAddon(register(new ImageAddon()));
                        console.log('[ttyd] Sixel enabled');
                    }
                    break;
                case 'titleFixed':
                    if (!value || value === '') return;
                    console.log(`[ttyd] setting fixed title: ${value}`);
                    this.titleFixed = value;
                    document.title = value;
                    break;
                default:
                    console.log(`[ttyd] option: ${key}=${JSON.stringify(value)}`);
                    if (terminal.options[key] instanceof Object) {
                        terminal.options[key] = Object.assign({}, terminal.options[key], value);
                    } else {
                        terminal.options[key] = value;
                    }
                    if (key.indexOf('font') === 0) fitAddon.fit();
                    break;
            }
        });
    }

    setRendererType = (value) => {
        const { terminal } = this;
        const disposeCanvasRenderer = () => {
            try {
                this.canvasAddon?.dispose();
            } catch {
                // ignore
            }
            this.canvasAddon = undefined;
        };
        const disposeWebglRenderer = () => {
            try {
                this.webglAddon?.dispose();
            } catch {
                // ignore
            }
            this.webglAddon = undefined;
        };
        const enableCanvasRenderer = () => {
            if (this.canvasAddon) return;
            this.canvasAddon = new CanvasAddon();
            disposeWebglRenderer();
            try {
                this.terminal.loadAddon(this.canvasAddon);
                console.log('[ttyd] canvas renderer loaded');
            } catch (e) {
                console.log('[ttyd] canvas renderer could not be loaded, falling back to dom renderer', e);
                disposeCanvasRenderer();
            }
        };
        const enableWebglRenderer = () => {
            if (this.webglAddon) return;
            this.webglAddon = new WebglAddon();
            disposeCanvasRenderer();
            try {
                this.webglAddon.onContextLoss(() => {
                    this.webglAddon?.dispose();
                });
                terminal.loadAddon(this.webglAddon);
                console.log('[ttyd] WebGL renderer loaded');
            } catch (e) {
                console.log('[ttyd] WebGL renderer could not be loaded, falling back to canvas renderer', e);
                disposeWebglRenderer();
                enableCanvasRenderer();
            }
        };

        switch (value) {
            case 'canvas':
                enableCanvasRenderer();
                break;
            case 'webgl':
                enableWebglRenderer();
                break;
            case 'dom':
                disposeWebglRenderer();
                disposeCanvasRenderer();
                console.log('[ttyd] dom renderer loaded');
                break;
            default:
                break;
        }
    }
}