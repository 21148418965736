import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import styles from './DropDown.module.css';

export function DropDown(props) {

  const navigate = useNavigate();

  const {
    selectedIntegrationSlug,
    integrations,
    loggedInUserOrganization,
  } = props


  const selectedIntegration = integrations.find(integration => integration.slug === selectedIntegrationSlug)
  const selectedIntegrationName = selectedIntegration?.label || "New";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {selectedIntegrationName}
        <ArrowDropDownIcon fontSize="small" />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          integrations.map(integration => {
            return (
              <MenuItem 
                key={`dropdown-integration-${integration.id}`}
                onClick={(e) => {
                  handleClose(e); 
                  navigate(integration.to)
                }}
              >
                {integration.label}
              </MenuItem>
            )
          })
        }
        { 
          integrations?.length > 0 && <Divider />
        }
        <MenuItem onClick={(e) => {
          const path = `/${loggedInUserOrganization.slug}/github/new`;
          handleClose(e); 
          navigate(path)
        }}>
          <AddCircleOutlineIcon sx={{mr: "5px"}} />Add Organization
        </MenuItem>
      </Menu>
    </div>
  );
}
