/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RepositoryDetails

    TODO: Add the following lines in src/app/store.js:
    import repositoryDetailsListenerCreators from '../features/repositoryDetails/repositoryDetailsListeners';
    repositoryDetailsListenerCreators.forEach(listener => listener(listenerMiddleware));
*/

import {
    init as initAction,
    fetchDetailsAsyncIfSlugIsDefined,
} from './repositoryDetailsSlice';

const createInitListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: initAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchDetailsAsyncIfSlugIsDefined(action.payload));
        },
    });
}

const listeners = [
    createInitListener,
]

export default listeners;