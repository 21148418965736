/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Actions

    TODO:: Add the following lines in src/app/store.js:
    import actionsReducer from '../features/actions/actionsSlice';
    reducer['actions'] = actionsReducer;
*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDetails } from './actionsAPI';

const initialState = {
    details: {
        workflowConclusionDistribution: [],
        latestWorkflowRunJobs: [],
        topFailingWorkflows: [],
        topAvgBuildTimeWorkflows: [],
        jobTimeAvgs: [],
    },
    detailsFetchStatus: 'idle',
    detailsFetchError: undefined,
};

export const fetchDetailsAsync = createAsyncThunk(
    'actions/fetchDetails',
    async (payload) => {
        const response = await fetchDetails(payload);
        return response.data;
    }
);

export const actionsSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        init: (state, action) => {
            const payload = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsAsync.pending, (state) => {
                state.detailsFetchStatus = 'loading';
                state.detailsFetchError = undefined;
            })
            .addCase(fetchDetailsAsync.fulfilled, (state, action) => {
                state.detailsFetchStatus = 'idle';
                const { data } = action.payload;
                state.details.workflowConclusionDistribution = data.distribution;
                state.details.latestWorkflowRunJobs = data.latestJobs;
                state.details.topFailingWorkflows = data.topFailingWorkflows;
                state.details.topAvgBuildTimeWorkflows = data.topAvgBuildTimeWorkflows;
                state.details.jobTimeAvgs = data.jobTimeAvgs;

            })
            .addCase(fetchDetailsAsync.rejected, (state, action) => {
                state.detailsFetchStatus = 'idle';
                state.detailsFetchError = action.error.message;
            })
    }
});

export const { init } = actionsSlice.actions;

export const selectActions = (state) => state.actions;

export default actionsSlice.reducer;


