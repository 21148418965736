/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Bolt

    TODO:: Add the following lines in src/app/store.js:
    import boltReducer from '../features/bolt/boltSlice';
    reducer['bolt'] = boltReducer;
*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { boltRepo as boltRepoAPI, fetchDetails, fetchRepos } from './boltAPI';

const initialState = {
    details: {
        "orgs": [],
        "repos": {},
        "selectedOrg": undefined,
    },
    boltStatus: {},
    boltError: {},
    detailsFetchStatus: 'idle',
    detailsFetchError: undefined,
    authenticated: true,
};

export const fetchDetailsAsync = createAsyncThunk(
    'bolt/fetchDetails',
    async (payload) => {
        const response = await fetchDetails(payload);
        return response.data;
    }
);

export const fetchReposAsync = createAsyncThunk(
    'bolt/fetchRepos',
    async (payload) => {
        const response = await fetchRepos(payload);
        return response.data;
    }
)

export const boltRepoAsync = createAsyncThunk(
    'bolt/boltRepos',
    async (payload) => {
        console.log(payload)
        const response = await boltRepoAPI(payload);
        return response.data;
    }
)

export const boltSlice = createSlice({
    name: 'bolt',
    initialState,
    reducers: {
        init: (state, action) => {
            const payload = action.payload;
        },
        selectOrg: (state, action) => {
            state.details.selectedOrg = action.payload;
        },
        boltRepo: (state, action) => {
            console.log(action);
            // const { orgName, repoName } = action.payload;
            // state.boltStatus[`${orgName}-${repoName}`] = 'loading';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsAsync.pending, (state) => {
                state.detailsFetchStatus = 'loading';
                state.detailsFetchError = undefined;
            })
            .addCase(fetchDetailsAsync.fulfilled, (state, action) => {
                state.detailsFetchStatus = 'idle';
                const { orgs } = action.payload;
                state.details.orgs = orgs;
                state.authenticated = true;
            })
            .addCase(fetchDetailsAsync.rejected, (state, action) => {
                state.detailsFetchStatus = 'idle';
                state.detailsFetchError = action.error.message;
                console.log(action);
                if (action.error.message === "Request failed with status code 401") {
                    state.authenticated = false;
                }

            })
            .addCase(fetchReposAsync.pending, (state) => {
                state.reposFetchStatus = 'loading';
                state.reposFetchError = undefined;
            })
            .addCase(fetchReposAsync.fulfilled, (state, action) => {
                state.reposFetchStatus = 'idle';
                const { repos, orgName } = action.payload;
                state.details.repos[orgName] = repos;
            })
            .addCase(fetchReposAsync.rejected, (state, action) => {
                state.reposFetchStatus = 'idle';
                state.reposFetchError = action.error.message;
            })
            .addCase(boltRepoAsync.pending, (state, action) => {
                const { orgName, repoName } = action.meta.arg;
                state.boltStatus[`${orgName}-${repoName}`] = 'loading';
            })
            .addCase(boltRepoAsync.fulfilled, (state, action) => {
                const { orgName, repoName } = action.payload;
                state.boltStatus[`${orgName}-${repoName}`] = 'idle';
            })
            .addCase(boltRepoAsync.rejected, (state, action) => {
                console.log(action)
                const { orgName, repoName } = action.meta.arg;
                const key = `${orgName}-${repoName}`;
                state.boltStatus[key] = 'idle';
                state.boltError[key] = action.error.message;
            })
    }
});

export const { init, selectOrg, boltRepo } = boltSlice.actions;

export const selectBolt = (state) => state.bolt;

export default boltSlice.reducer;


