import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedTabId: 1,
    tabs: [
        {
            id: 1,
            label: "Access Requests",
            disabled: false,
            slug: "access-requests",
        },
        {
            id: 2,
            label: "Resources",
            disabled: false,
            slug: "resources",
        },
        {
            id: 3,
            label: "Past Sessions",
            disabled: true,
            slug: "past-sessions",
        },
    ],
};

export const headerSlice = createSlice({
    name: "header",
    initialState,
    reducers: {
        selectTab: (state, action) => {
            state.selectedTabId = action.payload;
        }
    },
});

export const { selectTab } = headerSlice.actions;

export const selectHeader = (state) => state.header;

export default headerSlice.reducer;