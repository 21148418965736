/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RepositoryDetails

    TODO:: Add the following lines in src/app/store.js:
    import repositoryDetailsReducer from '../features/repositoryDetails/repositoryDetailsSlice';
    reducer['repositoryDetails'] = repositoryDetailsReducer;
*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDetails } from './repositoryDetailsAPI';

const initialState = {
    details: {},
    detailsFetchStatus: 'idle',
    detailsFetchError: undefined,
};

export const fetchDetailsAsync = createAsyncThunk(
    'repositoryDetails/fetchDetails',
    async (payload) => {
        const response = await fetchDetails(payload);
        return response.data;
    }
);

export const fetchDetailsAsyncIfSlugIsDefined = (payload) => (dispatch) => {
    const { repositorySlug } = payload;
    if (repositorySlug !== undefined) {
      dispatch(fetchDetailsAsync(payload));
    }
};

export const repositoryDetailsSlice = createSlice({
    name: 'repositoryDetails',
    initialState,
    reducers: {
        init: (state, action) => {
            const payload = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsAsync.pending, (state) => {
                state.detailsFetchStatus = 'loading';
                state.detailsFetchError = undefined;
            })
            .addCase(fetchDetailsAsync.fulfilled, (state, action) => {
                state.detailsFetchStatus = 'idle';
                const { data, repositorySlug } = action.payload;
                state.details[repositorySlug] = data;
            })
            .addCase(fetchDetailsAsync.rejected, (state, action) => {
                state.detailsFetchStatus = 'idle';
                state.detailsFetchError = action.error.message;
            })
    }
});

export const { init } = repositoryDetailsSlice.actions;

export const selectRepositoryDetails = (state) => state.repositoryDetails;

export default repositoryDetailsSlice.reducer;


