import * as React from 'react';
import styles  from './ConnectGithub.module.css';

import { 
    Box,
    Button,
    Typography,
} from '@mui/material';

import GitHubIcon from '@mui/icons-material/GitHub';

export function ConnectGithub(props) {
    const appID = ""
    return (
        <Box className={styles.newIntegrationContainer}>
            <GitHubIcon sx={{fontSize: '128px', color: '#2b5270'}} />
            <Typography component={'div'} variant='h6' sx={{margin: '20px 0 0 0', color: 'rgba(0,0,0,0.7)'}}>
                Connect Github organization
            </Typography>
            <Typography component={'div'} variant='body2' sx={{margin: '20px 0', color: 'rgba(0,0,0,0.7)'}}>
                On clicling <b>Connect Github</b>,  You will be redirected to Github to authorize Koala Lab to access your Github configurations.
            </Typography>
            <Button variant="contained" color="primary"
                onClick={() => {
                    window.location.replace('https://github.com/apps/koalalab-test/installations/new')
                }}
            >
                Connect Github
            </Button>
        </Box>
    )
}