import React from "react";
import { useSelector } from "react-redux";
import { ReactTerminal } from "../../components/terminal/ReactTerminal";
import { Outlet } from "react-router-dom";
import { Header } from "../header/Header";
import { HeaderTabs } from "../header/HeaderTabs";
import Box from "@mui/material/Box";
import { selectTerminal } from "../../components/terminal/terminalSlice";

export function Layout() {
    const terminal = useSelector(selectTerminal)
    const { terminalHeight, showTerminal } = terminal;
    return (
        <div>
            <Header /> { /* z-index 11 */}
            <Outlet />
            {/* <Box sx={{ width: '100%' }}>
                <HeaderTabs /> 
                <Box sx={{zIndex: 8}}>
                    <Outlet />
                </Box>
                {showTerminal && <Box sx={{ height: terminalHeight, overflow: 'auto' }}/>}
            </Box>
            <ReactTerminal path={"http://localhost/port/8082"} /> */}
        </div>
    )
}