import React from 'react';
import { 
    Box
} from '@mui/material';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '10px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
);

const IssueTitle = ({issues, children}) => {
    return (
      <React.Fragment>
        {`${issues.length} Issue${issues.length > 1 ? 's' : ''} found`} 
        {bull} 
        {children}
      </React.Fragment>
    )
}

export const IssueConfig = {
    "KoalaLab/Github/AccessTokenIssue/ExcessivePermission": {
      sectionHeader: {
        title: (issues) => 
          <IssueTitle issues={issues}>
            {`Personal Access Token with excessive permissions.`}
          </IssueTitle>
      },
      iconColor: 'warning',
      slug: "access-tokens",
      text: "Personal Access Token has excessive permissions.",
    },
    "KoalaLab/Github/DeployKeyIssue/GhostUserKey": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Deploy Key added by ghost users.`}
          </IssueTitle>
      },
      iconColor: 'error',
      slug: "deploy-keys",
      text: "Deploy Key added by ghost users.",
    },
    "KoalaLab/Github/DeployKeyIssue/StaleKey": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}> 
            {`Deploy Key older than 1 year.`}
          </IssueTitle>
      },
      iconColor: 'warning',
      slug: "deploy-keys",
      text: "Deploy Key older than 1 year.",
    },
    "KoalaLab/Github/DeployKeyIssue/ReadWriteKey": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}> 
            {`Deploy Key found with write access.`}
          </IssueTitle>
      },
      iconColor: 'info',
      slug: "deploy-keys",
      text: "Deploy Key found with write access.",
    },
    "KoalaLab/Github/ActionIssue/ThirdPartyActionWithoutHash": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Third party actions without hash.`}
          </IssueTitle>
      },
      iconColor: 'error',
      text: "3rd party actions without hash.",
      slug: "actions",
    },
    "KoalaLab/Github/ActionIssue/UnsecureCommandsAllowed": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Unsecure commands allowed using ALLOW_UNSECURE_COMMANDS flag.`}
          </IssueTitle>
      },
      iconColor: 'error',
      slug: "actions",
      text: "Unsecure commands allowed using ALLOW_UNSECURE_COMMANDS flag.",
    },
    "KoalaLab/Github/ActionIssue/PullRequestTargetEventTrigger": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Pull_request_target event trigger found in actions`}
          </IssueTitle>
      },
      iconColor: 'error',
      slug: "actions",
      text: "Pull_request_target event trigger found in actions",
    },
    "KoalaLab/Github/ActionIssue/DangerousPermissions": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Actions with dangerous permissions.`}
          </IssueTitle>
      },
      iconColor: 'error',
      slug: "actions",
      text: "Actions with dangerous permissions.",
    },
    "KoalaLab/Github/ActionIssue/ExpressionInjections": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Actions with expression injections vulnerability.`}
          </IssueTitle>
      },
      iconColor: 'error',
      slug: "actions",
      text: "Actions with expression injections vulnerability.",
    },
    "KoalaLab/Github/ActionIssue/CreateOrApprovePR": {
      sectionHeader: {
        title: (issues) =>
          <IssueTitle issues={issues}>
            {`Actions that create or approve Pull Requests.`}
          </IssueTitle>
      },
      iconColor: 'error',
      slug: "actions",
      text: "Actions that create or approve Pull Requests.",
    },
}