import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export function VerticalTabs({ tabs, selectedTabSlug, selectTabAction }) {
  const handleChange = (event, newValue) => {
    selectTabAction(newValue);
  };

  return (
    <Tabs TabIndicatorProps={{style: {display: 'none'}}} 
      value={selectedTabSlug} 
      onChange={handleChange} 
      orientation="vertical"
    >
      {
        tabs.map((tab) =>  (
          <Tab 
            key={`tab-${tab.id}`} 
            value={tab.slug} 
            style={{alignItems: 'flex-start', cursor: 'pointer'}} 
            label={tab.label} 
            disabled={tab.disabled}
          />
        ))
      }
    </Tabs>
  );
}