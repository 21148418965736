/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RunDetails

    TODO:: Add the following lines in src/app/store.js:
    import runDetailsReducer from '../features/runDetails/runDetailsSlice';
    reducer['runDetails'] = runDetailsReducer;
*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDetails } from './runDetailsAPI';

const initialState = {
    details: {
        jobDetails: {},
        egressReport: [],
        allJobAttemptsIDMap: {},
    },
    detailsFetchStatus: 'idle',
    detailsFetchError: undefined,
};

export const fetchDetailsAsync = createAsyncThunk(
    'runDetails/fetchDetails',
    async (payload) => {
        console.log(payload)
        const response = await fetchDetails(payload);
        return response.data;
    }
);

export const runDetailsSlice = createSlice({
    name: 'runDetails',
    initialState,
    reducers: {
        init: (state, action) => {
            const payload = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDetailsAsync.pending, (state) => {
                state.detailsFetchStatus = 'loading';
                state.detailsFetchError = undefined;
            })
            .addCase(fetchDetailsAsync.fulfilled, (state, action) => {
                state.detailsFetchStatus = 'idle';
                const { jobDetails, egressReport, allJobAttemptsIDMap } = action.payload.data;
                    state.details.jobDetails = jobDetails;
                    state.details.egressReport = egressReport;
                    state.details.allJobAttemptsIDMap = allJobAttemptsIDMap;
            })
            .addCase(fetchDetailsAsync.rejected, (state, action) => {
                state.detailsFetchStatus = 'idle';
                state.detailsFetchError = action.error.message;
            })
    }
});

export const { init } = runDetailsSlice.actions;

export const selectRunDetails = (state) => state.runDetails;

export default runDetailsSlice.reducer;


