import TableRow from '@mui/material/TableRow';
import styled from '@mui/material/styles/styled';

export const StyledTableRow = styled(TableRow)(() => ({
    '& > td': {
        align: 'center',
    },
    '&:nth-of-type(odd)': {
        backgroundColor: '#fafafa',
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#ffffff',
    },
    '&:last-child td, &:last-child th': {
        border: '5px',
    },
}));