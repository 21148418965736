import React from "react";

import {
    Button,
    Typography,
    Stack,
    Table,
    TableBody,
    TableCell,
    Divider,
} from '@mui/material';

import {
    Verified as VerifiedIcon,
    OpenInNew as OpenInNewIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

import { StyledTableRow } from '../../components/styledTableRow/StyledTableRow';

import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';

import {
    infoColor,
    openLinkInNewTab,
} from '../../components/constants/Constants';
import { CheckList } from "../../components/checkList/CheckList";

const IssueTitle = ({issues, children}) => {
    return (
      <React.Fragment>
        {children} - {`${issues.length} Issue${issues.length > 1 ? 's' : ''} found`} 
      </React.Fragment>
    )
}

export function Action(props) {
    const { actions } = props;

    return (
        <CollapsibleContainer title="Actions">
            <Stack sx={{px: 2, pb: 2}}>
                {
                    actions && actions.length === 0
                    &&   
                    <Stack direction="column"  sx={{mt: 2}}>
                        <Stack direction="column" alignItems="center" 
                            sx={{borderRadius: 1, bgcolor: 'rgb(240 248 255)', p: 1 }}>
                            <InfoIcon sx={{ fontSize: 24, color: infoColor }} />
                            <Typography variant="body2" 
                                sx={{mt: 1, textAlign: 'center', px: 3, color: 'rgb(1, 67, 97)'}}>
                                You don't have any third party actions in this Github repository.
                            </Typography>
                        </Stack>
                    </Stack>
                }
                {
                    actions && actions.length > 0 
                    &&
                    <Stack direction="column" sx={{mt: 2}}>
                        <Table size="small">
                            <TableBody>
                                {
                                    actions.map((action) => {
                                        return (
                                            <StyledTableRow key={action.id} sx={{height: '10px'}}>
                                                <TableCell >
                                                    <Typography >
                                                        {action.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{width: '141px'}} >
                                                    <Button variant="text"
                                                        size="small"
                                                        href={action.occurences[0]}
                                                        target="_blank"
                                                        endIcon={<OpenInNewIcon />}
                                                    >
                                                        View on Github
                                                    </Button>
                                                </TableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Stack>
                }  
            </Stack>
        </CollapsibleContainer>
    )
}