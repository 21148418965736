import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";

import {
    fetchSessionIfLoggedInKeyIsUndefined,
    selectSession,
} from './sessionSlice';

export function ProtectedRoute() {
  const session = useSelector(selectSession);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  // eslint-disable-next-line
  useEffect(() => {dispatch(fetchSessionIfLoggedInKeyIsUndefined())}, [])

  if (session.fetchStatus !== 'idle' ) {
    return (
      <div>Loading....</div>
    )
  } 
  if (session.fetchStatus === 'idle' && session.loggedInUser){
    return (
      <Outlet />
    );
  }
  if (session.logoutStatus === 'success') {
    navigate("/login")
    window.location.reload()
  }
  const redirectTo = encodeURIComponent(location.pathname + location.search + location.hash)
  if(redirectTo.length > 0)  {
    navigate(`/login?redirect_to=${redirectTo}`)
  }
  else {
    navigate("/login")
  }
}
