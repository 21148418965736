/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/component -o src/components PageWrapper
*/
import React from 'react'

import styles from './RepositoryScore.module.css'
import { Typography } from '@mui/material'
import { okThreshold, dangerThreshold } from '../constants/Constants';

export function RepositoryScore({value}) {
  let className;
  if (value < dangerThreshold) {
    className = styles.danger;
  } else if (value >= okThreshold) {
    className = styles.ok;
  } else {
    className = styles.warn;
  }
  return (
    <Typography className={`${styles.score} ${className}`} variant="body1">
      {(value/10).toFixed(1)}
    </Typography>
  )
}