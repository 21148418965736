import React from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import { Login } from './features/login/login';
import './App.css';

import {
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { ProtectedRoute } from './features/session/ProtectedRoute';

import { NotFound } from './features/errorPages/NotFound';
import { Layout } from './features/layout/Layout';
import { Dashboard } from './features/dashboard/Dashboard';

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'
import { selectSession } from './features/session/sessionSlice';
import { IntegrationDetails } from './features/integrationDetails/IntegrationDetails';
import { Repositories } from './features/repositories/Repositories';
import { RepositoryDetails } from './features/repositoryDetails/RepositoryDetails';
import { Actions } from './features/actions/Actions';
import { RunDetails } from './features/runDetails/RunDetails';
import { Bolt } from './features/bolt/Bolt';

TimeAgo.addDefaultLocale(en)


axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://localhost:3001/';
axios.defaults.baseURL = 'https://api-do-blr.koalalab.com/';
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error, data) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const location = window.location
  if(error.response.status === 401 && location.pathname !== "/login" && location.pathname !== "/bolt") {
    const redirectTo = encodeURIComponent(location.pathname + location.search + location.hash)
    location.href = "/login?redirect_to=" + redirectTo
  }
  return Promise.reject(error);
});

// let AuthContext = React.createContext({user: null});

const RedirectToOrgPage = () => {
  const session = useSelector(selectSession);

  const { loggedInUserOrganization } = session;
  const slug = loggedInUserOrganization?.slug;
  return (
    <Navigate to={`/${slug}`} />
  )
}

const RedirectToGithub = () => {
  const { org }  = useParams();
  return ( 
    <Navigate to={`/${org}/github`} />
  )
}

function App() {
  return (
    // <AuthProvider>
      <Routes>
        <Route path="/login" element={<Login mode="login" />} />
        <Route path="/signup" element={<Login mode="signup" />} />
        <Route path="/bolt" element={<Bolt />} />
        <Route element={<ProtectedRoute />} >
          <Route element={<Layout />} >
            <Route path="" element={<RedirectToOrgPage />} />
            <Route path="/" element={<RedirectToOrgPage />} />
            <Route path="/:org" element={<RedirectToGithub />} />
            <Route path="/:org/github/" element={<Dashboard />} />
            <Route path="/:org/github/:integrationSlug"
              element={<Dashboard/>} >
              <Route path="/:org/github/:integrationSlug/repositories" 
                element={<IntegrationDetails tabSlug="repositories" ><Repositories /></IntegrationDetails>} >
                  <Route path="/:org/github/:integrationSlug/repositories/:repositorySlug"
                    element={<RepositoryDetails />} /> 
              </Route>
              <Route path="/:org/github/:integrationSlug/actions" 
                element={<IntegrationDetails tabSlug="actions" ><Actions /></IntegrationDetails>} >
                  <Route path="/:org/github/:integrationSlug/actions/run-details/:jobSlug"
                    element={<RunDetails />} />
              </Route>
              <Route path="/:org/github/:integrationSlug/:tabSlug?" 
                element={<IntegrationDetails />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    // </AuthProvider>
  );
}

export default App;
