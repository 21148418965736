import React from "react";

import {
    Alert,
    AlertTitle,
    Button,
    Stack,
} from '@mui/material';

import {
    ArrowOutward as ArrowOutwardIcon,
} from '@mui/icons-material';

import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';

import {
    openLinkInNewTab,
} from '../../components/constants/Constants';
import { CheckList } from "../../components/checkList/CheckList";

export function Protection(props) {
    const { branchProtection } = props;

    const defaultBrancProtected = branchProtection?.enabled;
    const deletionDisabled = branchProtection?.deletion_disabled;
    const forcePushDisabled = branchProtection?.force_push_disabled;
    const prReviewRequired = branchProtection?.pr_review_enabled;
    const prRequiredReviewCount = branchProtection?.pr_required_review_count;
    const prStaleReviewsDisabled = branchProtection?.pr_stale_review_disabled;
    const codeownersEnabled = branchProtection?.pr_codeowner_enabled;
    const prBypassDisabled = branchProtection?.pr_bypass_disabled;
    return (
        <CollapsibleContainer title="Branch/PR/Tag Protection">
            {
                !defaultBrancProtected 
                &&
                <Alert severity="error" sx={{mt: 1}}>
                    <AlertTitle>Default branch is not protected in this repository</AlertTitle>
                    
                    Default branch should always have protection rules configured. In case you are using some other branch as default branch, please update the default branch in repository settings.
                    <br />
                    <br />
                    <Button color="inherit" 
                        sx={{left: "-5px"}}
                        variant="text"
                        onClick={() => openLinkInNewTab(`${props.githubUrl}/settings/branches`)}
                        endIcon={<ArrowOutwardIcon />}
                        size="small" >
                        <strong>Fix now on Github</strong>
                    </Button>
                </Alert>
            }
            <Stack sx={{px: 2, pb: 2}}>
            {
                <CheckList list={[
                    {
                        "label": defaultBrancProtected
                            ? "Default branch is protected"
                            : "Default branch is not protected",
                        "checked": defaultBrancProtected,
                        "level": defaultBrancProtected ? "info" : "danger",
                    },
                    {
                        "label": deletionDisabled
                            ? "Default branch deletion is not allowed."
                            : "Default Branch deletion is not disabled. This means that branches can be deleted.",
                        "checked": deletionDisabled,
                        "level": deletionDisabled ? "info" : "danger",
                    },
                    {
                        "label": forcePushDisabled
                            ? "Force push is disabled for default branch."
                            : "Force push is not disabled for default branch. This means that commits can be overwritten.",
                        "checked": forcePushDisabled,
                        "level": forcePushDisabled ? "info" : "danger",
                    },
                    {
                        "label": codeownersEnabled
                            ? "Codeowners are enabled for default branch."
                            : "Codeowners are not enabled for default branch. This means that pull requests can be merged without codeowners review.",
                        "checked": codeownersEnabled,
                        "level": codeownersEnabled ? "info" : "danger",
                    },
                    {
                        "label": prReviewRequired
                            ? "Default branch requires pull request for merging."
                            : "Pull requests are not mandatory for default branch.",
                        "checked": prReviewRequired,
                        "level": prReviewRequired ? "info" : "danger",
                    },
                    {
                        "label": prRequiredReviewCount && prRequiredReviewCount > 1
                            ? `Default branch requires ${prRequiredReviewCount} reviews before merging.`
                            : "Default branch requires less than 2 reviews before merging.",
                        "checked": prRequiredReviewCount && prRequiredReviewCount > 1,
                        "level": prRequiredReviewCount && prRequiredReviewCount > 1 ? "info" : "danger",
                    },
                    {
                        "label": prStaleReviewsDisabled
                            ? "Stale reviews are dismissed. This means that pull requests cannot be merged if there are commits after all the reviews."
                            : "Stale reviews are allowed. This means that pull requests can be merged even if there are commits after all the reviews.",
                        "checked": prStaleReviewsDisabled,
                        "level": prStaleReviewsDisabled ? "info" : "danger",
                    },
                    {
                        label: prBypassDisabled
                            ? "Pull request bypass is disabled for all users and teams on default branch."
                            : "Certain Users/Teams are allowed to bypass pull request reviews on default branch.",
                        checked: prBypassDisabled,
                        level: prBypassDisabled ? "info" : "info",
                    },
                ]} />
            }

            </Stack>
        </CollapsibleContainer>
    )
}