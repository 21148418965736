import { Component } from 'react';

// import './modal.scss';

export class Modal extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { show, children } = this.props;
        return (
            show && (
                <div className="modal">
                    <div className="modal-background" />
                    <div className="modal-content">
                        <div className="box">{children}</div>
                    </div>
                </div>
            )
        );
    }
}