import React from "react";

import { 
    Stack,
    Typography,
} from '@mui/material';

import {
    Verified as VerifiedIcon,
    Circle as CircleIcon,
} from '@mui/icons-material';

import { ResponsiveBar } from '@nivo/bar'

import {
    criticalColorWithOpacity,
    dangerColor,
    infoColor,
    okColor,
    okColorWithOpacity,
    warnColor,
    warnColorWithOpacity,
} from '../../components/constants/Constants';
import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';
import { CheckList } from "../../components/checkList/CheckList";


export function Dependabot(props) {
    const { dependabot: dependabotDetails } = props;
    console.log(dependabotDetails)
    
    const dependabotAlertKeys = [
        'Total',
        'Critical',
        'High',
        'Medium',
        'Low',
    ]
    const data = [
        {
            "Alerts": "Open Alerts",
            "Total": 0,
            "TotalColor": 'rgba(240, 248, 255, 1)',
            "Critical": dependabotDetails?.open_critical || 0,
            "CriticalColor": criticalColorWithOpacity(1),
            "High": dependabotDetails?.open_high || 0,
            "HighColor": criticalColorWithOpacity(0.8),
            "Medium": dependabotDetails?.open_medium || 0,
            "MediumColor": warnColorWithOpacity(0.6),
            "Low": dependabotDetails?.open_low || 0,
            "LowColor": warnColorWithOpacity(0.4),
        },
        {
            "Alerts": "Total Alerts",
            "Total": dependabotDetails?.open_total,
            "TotalColor": 'rgba(240, 248, 255, 1)',
            "Critical": 0,
            "CriticalColor": criticalColorWithOpacity(1),
            "High": 0,
            "HighColor": criticalColorWithOpacity(0.8),
            "Medium": 0,
            "MediumColor": warnColorWithOpacity(0.6),
            "Low": 0,
            "LowColor": warnColorWithOpacity(0.4),
        }
    ]

    const list = [
        {
            label: dependabotDetails?.enabled
                ? 'Dependabot Alerts are enabled for this repository.'
                : 'Dependabot Alerts are disabled for this repository.',
            checked: dependabotDetails?.enabled,
            level: dependabotDetails?.enabled ? "info" : "critical",
          }
    ]

    let recentActivities = []

    if (dependabotDetails?.new_critical > 0) {
        recentActivities.push(
            <Stack direction="row" alignItems="center">
                <CircleIcon sx={{width: 8, height: 8, mr: 1}} />
                <Typography variant="body2">
                    {dependabotDetails?.new_critical} new critical dependabot alerts found in this repository.
                </Typography>
            </Stack>
        )
    }

    if (dependabotDetails?.dismissed_recently_critical > 0) {
        recentActivities.push(
            <Stack direction="row" alignItems="center">
                <CircleIcon sx={{width: 8, height: 8, mr: 1}} />
                <Typography variant="body2">
                    {dependabotDetails?.dismissed_recently_critical} critical dependabot alerts were dismissed recently.
                </Typography>
            </Stack>
        )
    }

    return (
        <CollapsibleContainer title="Dependabot">
            <Stack sx={{px: 2, pb: 2}}>
                <CheckList list={list}/>
            {
                dependabotDetails?.enabled
                &&
                dependabotDetails?.open_total === 0
                &&
                <Stack direction="column" >
                    <Typography variant="body2" sx={{mt: 1}}>
                        Alert Distribution
                    </Typography>
                    <Stack direction="column" alignItems="center" 
                        sx={{borderRadius: 1, bgcolor: 'rgb(240 248 255)', p: 1 }}>
                        <VerifiedIcon sx={{ fontSize: 24, color: '#34b233' }} />
                        <Typography variant="body2" sx={{mt: 1}}>
                            Great!! No open dependabot alerts found in this repository.
                        </Typography>
                    </Stack>
                </Stack>
            }
            {
                dependabotDetails?.enabled
                &&
                dependabotDetails?.open_total > 0
                &&
                <Stack direction="column" >
                    <Typography variant="body2" sx={{mt: 1}}>
                        Alert Distribution
                    </Typography>
                    <Stack sx={{"height": "100px"}}> 
                        <ResponsiveBar
                            data={data}
                            keys={dependabotAlertKeys}
                            indexBy="Alerts"
                            layout="horizontal"
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            height={100}
                            colors={({id, data}) => {
                                return data[`${id}Color`]
                            }}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            label={({data, id, value}) => {
                                let total = 0;
                                dependabotAlertKeys.forEach(key => {
                                    total += data?.[key] || 0;
                                })
                                if(id === 'Total') {
                                    return `${value} Open Dependabot alerts found in this repository.`;
                                }
                                if(value/total > 0.1) {
                                    return `${value} - ${id}`
                                }
                                return value
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                            enableGridY={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            theme={{
                                fontSize: '14px',
                            }}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2.5
                                    ]
                                ]
                            }}
                        />
                    </Stack>
                </Stack>
            }
            {
                dependabotDetails?.enabled
                &&
                recentActivities.length > 0
                &&
                <Stack direction="column" >
                    <Typography variant="body2" sx={{mt: 1}}>
                        Recent Activity
                    </Typography>
                    <Stack direction="column" sx={{mt: 1}}>
                    {
                        recentActivities.map(activity => activity)
                    }
                    </Stack>
                </Stack>
            }
            </Stack>
        </CollapsibleContainer>
    )
}