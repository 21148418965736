import React from "react";

import {
    Alert,
    AlertTitle,
    Button,
    Typography,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Divider,
} from '@mui/material';

import {
    ArrowOutward as ArrowOutwardIcon,
    Verified as VerifiedIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

import ReactTimeAgo from 'react-time-ago'

import { StyledTableRow } from '../../components/styledTableRow/StyledTableRow';

import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';

import {
    infoColor,
    openLinkInNewTab,
} from '../../components/constants/Constants';
import { CheckList } from "../../components/checkList/CheckList";

const IssueTitle = ({issues, children}) => {
    return (
      <React.Fragment>
        {children} - {`${issues.length} Issue${issues.length > 1 ? 's' : ''} found`} 
        
      </React.Fragment>
    )
}

const deployKeyConfig = {
    "KoalaLab/Github/DeployKeyIssue/GhostUserKey": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Deploy Key added by ghost users`}
            </IssueTitle>
        },
        iconColor: 'error',
        slug: "deploy-keys",
        text: "Deploy Key added by ghost users",
    },
    "KoalaLab/Github/DeployKeyIssue/StaleKey": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}> 
              {`Deploy Key older than 1 year`}
            </IssueTitle>
        },
        iconColor: 'warning',
        slug: "deploy-keys",
        text: "Deploy Key older than 1 year",
    },
    "KoalaLab/Github/DeployKeyIssue/ReadWriteKey": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}> 
              {`Deploy Key found with write access`}
            </IssueTitle>
        },
        iconColor: 'info',
        slug: "deploy-keys",
        text: "Deploy Key found with write access",
    },
}

export function DeployKey(props) {
    const { deployKey: deployKeyIssues, count } = props;
    const ghostUserIssues = deployKeyIssues.filter(issue => issue.type === "KoalaLab/Github/DeployKeyIssue/GhostUserKey");

    return (
        <CollapsibleContainer title="Deploy Keys">
            {
                ghostUserIssues.length > 0
                &&
                <Alert severity="error" sx={{mt: 1}}>
                    <AlertTitle>Deploy Key present which  was created by user who has left organization.</AlertTitle>
                    
                    Even though we remove the user from SSO,  the deploy key created by the user still remains in the repository. This is a security risk as the user can still access the repository.
                    <br />
                    <br />
                    <Button color="inherit" 
                        sx={{left: "-5px"}}
                        variant="text"
                        onClick={() => openLinkInNewTab(ghostUserIssues[0]?.githubLink)}
                        endIcon={<ArrowOutwardIcon />}
                        size="small" >
                        <strong>Fix now on Github</strong>
                    </Button>
                </Alert>
            }
            <Stack sx={{px: 2, pb: 2}}>
                {
                    count === 0
                    &&   
                    <Stack direction="column"  sx={{mt: 2}}>
                        <Stack direction="column" alignItems="center" 
                            sx={{borderRadius: 1, bgcolor: 'rgb(240 248 255)', p: 1 }}>
                            <InfoIcon sx={{ fontSize: 24, color: infoColor }} />
                            <Typography variant="body2" 
                                sx={{mt: 1, textAlign: 'center', px: 3, color: 'rgb(1, 67, 97)'}}>
                                You don't have any Deploy Key associated with this repository. You should prefer using Deploy Key over Personal Access Token.
                            </Typography>
                        </Stack>
                    </Stack>
                }
                {
                    deployKeyIssues.length === 0 && count > 0
                    &&
                    <Stack direction="column"  sx={{mt: 2}}>
                        <Stack direction="column" alignItems="center" 
                            sx={{borderRadius: 1, bgcolor: 'rgb(240 248 255)', p: 1 }}>
                            <VerifiedIcon sx={{ fontSize: 24, color: '#34b233' }} />
                            <Typography variant="body2" 
                                sx={{mt: 1, textAlign: 'center', px: 3, color: 'rgb(1, 67, 97)'}}>
                                Great!! No Issues found with deploy keys.
                            </Typography>
                        </Stack>
                    </Stack>
                }
                {
                    deployKeyIssues.map(issue => {
                        const { sectionHeader, iconColor, slug, text } = deployKeyConfig[issue.type];
                        return (
                            <Stack direction="column" sx={{mt: 2}}>
                                <Typography variant="body2" sx={{mt: 1}}>
                                    {sectionHeader.title([issue])}
                                </Typography>
                                <Divider />
                                <Table size="small" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell >
                                                <Typography variant="body2">Key Name</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography variant="body2">Added By</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography variant="body2">Created</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography variant="body2">Last Used</Typography>
                                            </TableCell>
                                            <TableCell >
                                                <Typography variant="body2">Read Only</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            issue.keys?.map((key) => {
                                                return (
                                                    <TableRow key={key.id} sx={{height: '10px'}}>
                                                        <TableCell >
                                                            <Typography 
                                                                sx={{cursor: "pointer", color: "#0366d6"}}
                                                                onClick={() => openLinkInNewTab(issue.githubLink)}
                                                            >
                                                                {key.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>{key.addedBy}</Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                        <Typography>
                                                                <ReactTimeAgo date={key.createdAt} locale="en-US"/>
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>
                                                                {
                                                                    key.lastUsedAt 
                                                                    ? <ReactTimeAgo date={key.lastUsedAt} locale="en-US"/>
                                                                    : "Never"
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>{key.readOnly ? "Yes" : "No"}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </CollapsibleContainer>
    )
}