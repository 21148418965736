/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Bolt

    TODO: Add the following lines in src/app/store.js:
    import boltListenerCreators from '../features/bolt/boltListeners';
    boltListenerCreators.forEach(listener => listener(listenerMiddleware));
*/

import {
    init as initAction,
    selectOrg as selectOrgAction,
    boltRepo as boltRepoAction,
    fetchDetailsAsync,
    fetchReposAsync,
    boltRepoAsync,
} from './boltSlice';

const createInitListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: initAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchDetailsAsync(action.payload));
        },
    });
}

const createSelectOrgListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: selectOrgAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchReposAsync(action.payload));
        },
    });
}

const createBoltRepoListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: boltRepoAction,
        effect: async (action, listenerApi) => {
            console.log(action)
            listenerApi.dispatch(boltRepoAsync(action.payload));
        },
    });
}

const listeners = [
    createInitListener,
    createSelectOrgListener,
    createBoltRepoListener,
]

export default listeners;