import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import terminalReducer from '../components/terminal/terminalSlice';
import sessionReducer from '../features/session/sessionSlice';
import accessRequestsReducer from '../features/accessRequests/accessRequestsSlice';
import resourcesReducer from '../features/resources/resourcesSlice';
import headerReducer from '../features/header/headerSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import integrationDetailsReducer from '../features/integrationDetails/integrationDetailsSlice';
import repositoryDetailsReducer from '../features/repositoryDetails/repositoryDetailsSlice';
import repositoriesReducer from '../features/repositories/repositoriesSlice';
import actionsReducer from '../features/actions/actionsSlice';
import runDetailsReducer from '../features/runDetails/runDetailsSlice';
import boltReducer from '../features/bolt/boltSlice';

import AccessRequestsListenerCreators from '../features/accessRequests/accessRequestsListeners';
import ResourcesListenerCreators from '../features/resources/resourcesListeners';
import DashboardListenerCreators from '../features/dashboard/dashboardListeners';
import integrationDetailsListenerCreators from '../features/integrationDetails/integrationDetailsListeners';
import repositoryDetailsListenerCreators from '../features/repositoryDetails/repositoryDetailsListeners';
import repositoriesListenerCreators from '../features/repositories/repositoriesListeners';
import actionsListenerCreators from '../features/actions/actionsListeners';
import runDetailsListenerCreators from '../features/runDetails/runDetailsListeners';
import boltListenerCreators from '../features/bolt/boltListeners';

export const listenerMiddleware = createListenerMiddleware()

var reducer = {};
reducer['session'] = sessionReducer;
reducer['terminal'] = terminalReducer;
reducer['accessRequests'] = accessRequestsReducer;
reducer['resources'] = resourcesReducer;
reducer['header'] = headerReducer;
reducer['dashboard'] = dashboardReducer;
reducer['integrationDetails'] = integrationDetailsReducer;
reducer['repositoryDetails'] = repositoryDetailsReducer;
reducer['repositories'] = repositoriesReducer;
reducer['actions'] = actionsReducer;
reducer['runDetails'] = runDetailsReducer;
reducer['bolt'] = boltReducer;

export const store = configureStore({
  reducer: reducer,
  
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

AccessRequestsListenerCreators.forEach(listener => listener(listenerMiddleware));
ResourcesListenerCreators.forEach(listener => listener(listenerMiddleware));
DashboardListenerCreators.forEach(listener => listener(listenerMiddleware));
integrationDetailsListenerCreators.forEach(listener => listener(listenerMiddleware));
repositoryDetailsListenerCreators.forEach(listener => listener(listenerMiddleware));
repositoriesListenerCreators.forEach(listener => listener(listenerMiddleware));
actionsListenerCreators.forEach(listener => listener(listenerMiddleware));
runDetailsListenerCreators.forEach(listener => listener(listenerMiddleware));
boltListenerCreators.forEach(listener => listener(listenerMiddleware));
