import {
    selectTab as selectTabAction,
    fetchIntegrationDetailsAsync,
} from './integrationDetailsSlice';

const createFetchIntegrationDetailsListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: selectTabAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchIntegrationDetailsAsync(action.payload));
        },
    });
}

const listeners = [
    createFetchIntegrationDetailsListener,
]

export default listeners;