import React from "react";

import {
    Alert,
    AlertTitle,
    Button,
    Stack,
} from '@mui/material';

import {
    ArrowOutward as ArrowOutwardIcon,
} from '@mui/icons-material';

import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';
import { CheckList } from "../../components/checkList/CheckList";

import { openLinkInNewTab } from "../../components/constants/Constants";

export function CodeScanning(props) {
    const { codeScanning } = props;
    const codeScanningEnabled = codeScanning?.enabled;
    return (
        <CollapsibleContainer title="Code Scanning" initOpen={false}>
            <Alert severity="info" sx={{mt: 1}}>
                <AlertTitle>Code Scanning</AlertTitle>
                Code scanning Alerts are available only with Github Advanced Security add-on.
                <br />
                <br />
                <Button color="inherit" 
                    sx={{left: "-5px"}}
                    variant="text"
                    onClick={() => openLinkInNewTab(`https://docs.github.com/en/code-security/code-scanning`)}
                    endIcon={<ArrowOutwardIcon />}
                    size="small" >
                    <strong>Click here to learn more</strong>
                </Button>
            </Alert>
            <Stack sx={{px: 2, pb: 2}}>
                <CheckList list={[
                    {
                        label: codeScanningEnabled 
                            ? "Code Scanning Alerts are enabled for this repository"
                            :  "Code Scanning Alerts are disabled for this repository",
                        checked: codeScanningEnabled,
                        level: codeScanningEnabled ? "info" : "info",
                    },
                ]} />
            </Stack>
        </CollapsibleContainer>
    )
}