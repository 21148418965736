/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/component -o src/components CheckList
*/
import React from 'react'

import styles from './CheckList.module.css'
import { 
    Stack, Typography,
    List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material'

import {
  DisabledByDefault as DisabledByDefaultIcon,
  CheckBox as CheckBoxIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  Comment as CommentIcon,
} from '@mui/icons-material';

import {
  criticalColorWithOpacity,
  dangerColor,
  infoColor,
  okColor,
  okColorWithOpacity,
  warnColor,
  warnColorWithOpacity,
} from '../constants/Constants';

export function CheckList(props) {
  const { list } = props;
 
  return (
      list && <List size="small" dense>
        {
          list.map(({label, checked, level}, index) => {
            return (
              <ListItem key={index} sx={{px: 0}}>
                <ListItemIcon sx={{minWidth: 0, mr: 1}} >
                  {
                    checked
                    ? <CheckBoxIcon sx={{color: okColor}} />  
                    : (
                      level === "info"
                      ? <DisabledByDefaultIcon sx={{color: infoColor}} />
                      : <DisabledByDefaultIcon sx={{color: dangerColor}} />
                    )
                  }
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            )
          })
        }
    </List>
  )
}