import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import { 
    fetchAccessRequests,
    startWebauthnRegistration,
    startSessionForAccessRequest,
} from './accessRequestsAPI';

const initialState = {
    tabs: [
        { id: 1, label: 'Sent', slug: 'sent' },
        { id: 2, label: 'Received', slug: 'received' },
        { id: 3, label: 'Archived', slug: 'archived' },
    ],

    selectedTabId: 1,
};

const getSlug = (id) => {
    const tab = initialState.tabs.find(tab => tab.id === id);
    return tab.slug;
}

export const fetchAccessRequestsAsync = createAsyncThunk(
    'accessRequests/fetchAccessRequests',
    async (tabId) => {
        const slug = getSlug(tabId);
        const response = await fetchAccessRequests(slug);
        return response.data;
    }
);

export const startWebauthnRegistrationAsync = createAsyncThunk(
    'accessRequests/startWebauthnRegistration',
    async () => {
        const response = await startWebauthnRegistration();
        console.log(response)
        return response.data;
    }
);

export const startSessionForAccessRequestAsync = createAsyncThunk(
    'accessRequests/startSessionForAccessRequest',
    async (params) => {
        const response = await startSessionForAccessRequest(params);
        return response.data;
    }
);

export const accessRequestsSlice = createSlice({
    name: 'accessRequests',
    initialState,
    reducers: {
        selectTab: (state, action) => {
            state.selectedTabId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAccessRequestsAsync.fulfilled, (state, action) => {
            enqueueSnackbar('Successfully fetched access requests', { variant: 'success', autoHideDuration: 1000 })
        })
        .addCase(fetchAccessRequestsAsync.rejected, (state, action) => {
            enqueueSnackbar('Failed to fetch access requests.', { variant: 'error', autoHideDuration: 1000 });
        })
        .addCase(fetchAccessRequestsAsync.pending, (state, action) => {
            enqueueSnackbar('Fetching access requests...', { variant: 'info', autoHideDuration: 1000 });
        })
        .addCase(startWebauthnRegistrationAsync.fulfilled, (state, action) => {
            console.log(action)
        })
        .addCase(startWebauthnRegistrationAsync.rejected, (state, action) => {
            console.log(action)
        })
        .addCase(startWebauthnRegistrationAsync.pending, (state, action) => {
            console.log(action)
        })
        .addCase(startSessionForAccessRequestAsync.fulfilled, (state, action) => {
            console.log(action)
        })
        .addCase(startSessionForAccessRequestAsync.rejected, (state, action) => {
            console.log(action)
        })
        .addCase(startSessionForAccessRequestAsync.pending, (state, action) => {
            console.log(action)
        })
    }
});

export const { selectTab } = accessRequestsSlice.actions;

export const selectAccessRequests = (state) => state.accessRequests;

export default accessRequestsSlice.reducer;
