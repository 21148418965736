import React from "react";

import {
    Alert,
    AlertTitle,
    Button,
    Stack,
} from '@mui/material';

import {
    ArrowOutward as ArrowOutwardIcon,
} from '@mui/icons-material';

import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';
import { CheckList } from "../../components/checkList/CheckList";

import { openLinkInNewTab } from "../../components/constants/Constants";

export function SecretScanning(props) {
    const { secretScanning } = props;
    const secretScanningEnabled = secretScanning?.enabled;
    const secretScanningPushProtectionEnabled = secretScanning?.push_protection;
    return (
        <CollapsibleContainer title="Secret Scanning" initOpen={false}>
            <Alert severity="info" sx={{mt: 1}}>
                <AlertTitle>Secret Scanning</AlertTitle>
                Secret scanning Alerts are available only with Github Advanced Security add-on.
                <br />
                <br />
                <Button color="inherit" 
                    sx={{left: "-5px"}}
                    variant="text"
                    onClick={() => openLinkInNewTab(`https://docs.github.com/en/code-security/secret-scanning`)}
                    endIcon={<ArrowOutwardIcon />}
                    size="small" >
                    <strong>Click here to learn more</strong>
                </Button>
            </Alert>
            <Stack sx={{px: 2, pb: 2}}>
                <CheckList list={[
                    {
                        label: secretScanningEnabled 
                            ? "Secret Scanning Alerts are enabled for this repository"
                            :  "Secret Scanning Alerts are disabled for this repository",
                        checked: secretScanningEnabled,
                        level: secretScanningEnabled ? "info" : "info",
                    },
                    {
                        label: secretScanningPushProtectionEnabled
                            ? "Secret scanning push protection is enabled for this repository"
                            : "Secret scanning push protection is disabled for this repository",
                        checked: secretScanningPushProtectionEnabled,
                        level: secretScanningPushProtectionEnabled ? "info" : "info",
                    }
                ]} />
            </Stack>
        </CollapsibleContainer>
    )
}