import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { 
    fetchIntegrations,
} from './dashboardAPI';

const initialState = {
    integrations: undefined,
    selectedIntegrationSlug: undefined,
    
    fetchStatus: 'idle',
    fetchError:  undefined,
};

export const fetchIntegrationsAsync = createAsyncThunk(
    'dashboard/fetchIntegrations',
    async () => {
        const response = await fetchIntegrations();
        return response.data;
    }
);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        initAction: (state, action) => {
            state.fetchStatus = 'loading';
        },
        selectIntegration: (state, action) => {
            if (action.payload) {
                state.selectedIntegrationSlug = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationsAsync.pending, (state) => {
                state.fetchStatus = 'loading';
            })
            .addCase(fetchIntegrationsAsync.fulfilled, (state, action) => {
                state.fetchStatus = 'idle';
                state.integrations = action.payload.integrations;
            })
            .addCase(fetchIntegrationsAsync.rejected, (state, action) => {
                state.fetchStatus = 'idle';
                state.fetchError = action.error.message;
            })
    },
});

export const { initAction, selectIntegration } = dashboardSlice.actions;

export const selectDashboard = (state) => state.dashboard;

export default dashboardSlice.reducer;
