import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutAsync, selectSession } from '../session/sessionSlice';
import styles from "./Header.module.css";
import LogoImg from "./logo.png";
import Icon from '@mdi/react';
import { mdiChevronDoubleRight } from '@mdi/js';
import { DropDown } from '../../components/dropDown/DropDown';
import { AccountMenu } from '../../components/accountMenu/AccountMenu';
import { Typography } from '@mui/material';
import { selectDashboard, selectIntegration } from '../dashboard/dashboardSlice';
import { useNavigate } from 'react-router-dom';

export function Header() {
    const dispatch = useDispatch();
    const session = useSelector(selectSession);
    const dashboard = useSelector(selectDashboard);
    const navigate = useNavigate();
    const { integrations, selectedIntegrationSlug } = dashboard;  
    const { loggedInUser, loggedInUserOrganization } = session;

    const integrationsProp = integrations ? integrations.map((integration) => {
        return {
            "id": integration.id,
            "to": `/${loggedInUserOrganization.slug}/github/${integration.slug}/actions`,
            "label": integration.label,
            "slug": integration.slug,
        }
    }) : undefined;

    return (
        <div className={styles.headerContainer}>
            <div className={styles.orgContainer}>
                <div className={styles.logoContainer} onClick={() => navigate(`/${loggedInUserOrganization?.slug}`)}>
                    <img className={styles.logoImg} alt='logo' src={LogoImg} />
                    <Typography variant="h6" style={{color: "#101010"}}>Koala</Typography>
                    <Typography variant="h6" style={{color: "#2b5270"}}>Lab</Typography>
                </div>
                <Icon path={mdiChevronDoubleRight} size={1} style={{margin: '0 5px'}} />
                <Typography variant="body1" style={{color: "#101010"}}>Github</Typography>
                <Icon path={mdiChevronDoubleRight} size={1} style={{margin: '0 5px'}} />

                { 
                    integrations  && selectedIntegrationSlug 
                    && <DropDown 
                        integrations={integrationsProp}
                        selectedIntegrationSlug={selectedIntegrationSlug}
                        selectIntegrationAction={selectIntegration}
                        loggedInUserOrganization={loggedInUserOrganization}
                    /> 
                }
            </div>

            <div className={styles.userContainer}>
                <AccountMenu 
                    loggedInUser={loggedInUser} 
                    loggedInUserOrganization={loggedInUserOrganization}
                    logoutFunc={(e) =>  {e.preventDefault();dispatch(logoutAsync())}}
                />
            </div>
        </div>
    )
}