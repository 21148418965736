import axios from "axios";
import {
    create as createCredential,
    get as getAssertion,
    parseCreationOptionsFromJSON,
    parseRequestOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";

export const fetchAccessRequests = async (slug) => {
    console.log(slug)
    return await axios
        .get(`/access-requests/${slug}`)
}

export const startWebauthnRegistration = async () => {
    
    return await axios
        .get("/webauthn/registration/start")
        .then((res) => {
            const options = res.data?.options;
            return createCredential(parseCreationOptionsFromJSON(options))
        })
        .then((credential) => {
            return axios.post("/webauthn/register", credential.toJSON())
        })
}

export const startSessionForAccessRequest = async ({ requestId, target }) => {
    return await axios.get("/webauthn/verification/start")
        .then((res) => {
            const options = res.data?.options;
            return getAssertion(parseRequestOptionsFromJSON(options))
        })
        .then((assertion) => {
            console.log(assertion);
            const params = {
                webauthn: assertion.toJSON()
            }
            return axios.post(`/access-requests/${requestId}/sessions?target=${target}`, params)
        })
}