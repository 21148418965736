export const okThreshold = 80;
export const dangerThreshold = 50;

export const criticalColorWithOpacity = (opacity) => `rgba(220, 76, 100, ${opacity})`;
export const okColorWithOpacity = (opacity) => `rgba(20, 164, 77, ${opacity})`;
export const warnColorWithOpacity = (opacity) => `rgba(228, 161, 27, ${opacity})`;
export const infoColorWithOpacity= (opacity) => `rgba(84, 180, 211,  ${opacity})`

export const warnColor = warnColorWithOpacity(1);
export const dangerColor = criticalColorWithOpacity(1);
export const criticalColor = criticalColorWithOpacity(1);
export const okColor = okColorWithOpacity(1);
export const infoColor = infoColorWithOpacity(1);

export const openLinkInNewTab = ( url ) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if ( newTab ) newTab.opener = null;
}