/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Actions
*/

import React, { useEffect } from 'react';
import { useParams, Navigate, useNavigate, Outlet, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ResponsiveBar } from '@nivo/bar';

import  styles from './Actions.module.css';

import { selectActions, init as initAction } from './actionsSlice';

import { 
    Stack,
    Typography,
    Divider,
    SvgIcon,
    Collapse,
    Button,
    Box,
    Tooltip,
    Paper,
    Card,
    CardMedia,
    CardContent,
    CardActions,
} from '@mui/material';

import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';

import {
    Verified as VerifiedIcon,
} from '@mui/icons-material';
import { criticalColorWithOpacity, dangerColor, infoColorWithOpacity, okColor, okColorWithOpacity, warnColorWithOpacity } from '../../components/constants/Constants';

const runConclusionDistributionColors = {
    "successColor" : okColorWithOpacity(0.7),
    "failureColor" : criticalColorWithOpacity(0.8),
    "startup_failureColor": criticalColorWithOpacity(0.5),
    "cancelledColor": 'rgba(200,200,200,0.7)',
    "timed_outColor": criticalColorWithOpacity(0.7),
}

const avgJobTimeColors = {
    "avgQueueTimeColor": okColorWithOpacity(0.7),
    "avgBuildTimeColor": infoColorWithOpacity(0.7),
}

function formatDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
    const monthIndex = date.getMonth();
    const year = String(date.getFullYear()).slice(-2);
  
    return `${day}-${months[monthIndex]}`;
    return `${day}-${months[monthIndex]}-${year}`;
}

const  convertDateString = (inputDateString) => {
    // Parse the input date string (assuming it is in the format 'DD-MM-YYYY')
    const parts = inputDateString.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are zero-based in JavaScript
    const year = parseInt(parts[2], 10);
  
    // Create a Date object with the parsed values
    const dateObject = new Date(year, month, day);
  
    // Format the date to 'DD Mon' using toLocaleDateString
    const formattedDate = dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  
    return { formattedDate, dateObject};
  }

const processConclusionDistribution = (data) => {
    return data.map((row) => {
        row = {...runConclusionDistributionColors, ...row}
        const { dateObject, formattedDate } = convertDateString(row["date"])
        row["date"] = formattedDate;
        row["dateObject"] = dateObject;
        return row;
    }).sort((a, b) => a.dateObject - b.dateObject);
}

const processJobTimeAvgs = (data) => {
    return data.map((row) => {
        row = {...avgJobTimeColors, ...row}
        const { dateObject, formattedDate } = convertDateString(row["date"])
        row["date"] = formattedDate;
        row["dateObject"] = dateObject;
        return row;
    }).sort((a, b) => a.dateObject - b.dateObject);
}

const ConclusionDistribution = ({ data }) => (
    <ResponsiveBar
        data={data}
        keys={[
            'success',
            'failure',
            'startup_failure',
            'cancelled',
            'timed_out'
        ]}
        indexBy="date"
        margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
        padding={0.65}
        layout="vertical"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={false}
        axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: -45,
            truncateTickAt: 0
        }}
        animate={false}
        enableGridY={false}
        enableLabel={false}
        label={false}
        legends={[]}
        role="application"
    />
)

const JobTimeAvgs = ({ data }) => (
    <ResponsiveBar
        data={data}
        keys={[
            'avgQueueTime',
            'avgBuildTime',
        ]}
        indexBy="date"
        margin={{ top: 0, right: 0, bottom: 50, left: 0 }}
        padding={0.65}
        layout="vertical"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisLeft={false}
        axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: -45,
            truncateTickAt: 0
        }}
        animate={false}
        enableGridY={false}
        enableLabel={false}
        label={false}
        legends={[]}
        role="application"
    />
)

const TopList = ({ data, indexBy, keys }) => (
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 0, right: 250, bottom: 0, left: 0 }}
        padding={0.65}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisLeft={null}
        axisBottom={false}
        axisRight={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
            truncateTickAt: 0
        }}
        animate={false}
        enableGridY={false}
        enableLabel={false}
        label={false}
        legends={[]}
        role="application"
    />
)

const MyResponsiveBar = ({ data /* see data tab */ }) => (
    <ResponsiveBar
        data={data}
        keys={[
            'build failures',
        ]}
        indexBy="repository"
        margin={{ top: 0, right: 250, bottom: 0, left: 0 }}
        padding={0.65}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={({ id, data }) => data[`${id}Color`]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisLeft={null}
        axisBottom={false}
        axisRight={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
            truncateTickAt: 0
        }}
        animate={false}
        enableGridY={false}
        enableLabel={false}
        label={false}
        legends={[]}
        role="application"
    />
)

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

function RunButton(props) {
    const attempts = props.attempts;
    const bgColors = [okColorWithOpacity(0.8), criticalColorWithOpacity(0.8)];
    const conclusion = props.conclustion;
    const status = props.status;
    if(status != "completed"){
        return <Link style={{
                width: '24px', 
                height: '24px', 
                minWidth: '0px', 
                borderRadius: '12px', 
                margin: '0 2px',
                backgroundColor: warnColorWithOpacity(0.8),
                color: '#FFF',
                textAlign: 'center',
                fontSize: '12px',
                lineHeight: '24px',
                textDecoration: 'none',
                display: 'flex',
            }} 
        >
            -
        </Link>
    }
    return (
        <Link style={{
                width: '24px', 
                height: '24px', 
                minWidth: '24px', 
                borderRadius: '12px', 
                margin: '0 2px',
                backgroundColor: bgColors[conclusion == "success" ? 0 : 1],
                color: '#FFF',
                textAlign: 'center',
                fontSize: '12px',
                lineHeight: '24px',
                textDecoration: 'none',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
            }} 
            to={props.to}
        >
            {attempts == 1 && conclusion == "success"  ? <VerifiedIcon /> : attempts}
        </Link>
    )
}

function JobLabel(props) {
    return (
        <Tooltip title={props.children} placement="left">
            <Typography noWrap variant="body2" sx={{width: '250px', minWidth: '250px', pr: 2}}>{props.children}</Typography>
        </Tooltip>
    )
}

const CustomTreeItem = React.forwardRef((props, ref) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref} />
));

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
        opacity: 0.3,
    },
    '& .end': {
        display: 'none',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 7,
    paddingLeft: 0,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.content}`]: {
    padding: 0,
  },
  [`& .${treeItemClasses.content}:hover`]: {
    backgroundColor: '#FFF',
  },
  [`& .${treeItemClasses.content}.${treeItemClasses.selected}`]: {
    backgroundColor: '#FFF',
  },
  [`& .${treeItemClasses.content}.${treeItemClasses.focused}`]: {
    backgroundColor: '#FFF',
  },
}));

export function Actions(props) {
    const {org, integrationSlug, jobSlug} = useParams();
    if (jobSlug === undefined) {
        document.getElementsByTagName("body")[0].style.overflow = "auto";
    } else {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { details } = useSelector(selectActions);

    const  { 
        workflowConclusionDistribution, 
        latestWorkflowRunJobs,
        topFailingWorkflows,
        topAvgBuildTimeWorkflows,
        jobTimeAvgs,
    } = details;

    const latestWorkflowRunJobsByRepository = {}
    latestWorkflowRunJobs.map((job) => {
        const repoID = `RepoID::${job.githubRepoID}`;
        if (!latestWorkflowRunJobsByRepository[repoID]) {
            latestWorkflowRunJobsByRepository[repoID] = [];
        }   
        if (!latestWorkflowRunJobsByRepository[repoID][`${job.workflowName} / ${job.jobName}`]) {
            latestWorkflowRunJobsByRepository[repoID][`${job.workflowName} / ${job.jobName}`] = []
        }
        latestWorkflowRunJobsByRepository[repoID][`${job.workflowName} / ${job.jobName}`].push(job);
    })

    for (const repoID in latestWorkflowRunJobsByRepository) {
        const jobsMap = latestWorkflowRunJobsByRepository[repoID];
        for (const jobName in jobsMap) {
            const jobs = jobsMap[jobName].sort((a, b) => {
                return Date.parse(b.createdAt) - Date.parse(a.createdAt);
            })
            jobsMap[jobName] = jobs;
        }
        latestWorkflowRunJobsByRepository[repoID] = jobsMap;
    }

    const topListFailingWorkflows = topFailingWorkflows.map((workflow) => {
        const criticalCutoff = 10;
        const warningCutoff = 5;
        const failureCount = Math.floor(workflow.metric);
        let color = okColorWithOpacity(0.7);
        if (failureCount > criticalCutoff) {
            color = criticalColorWithOpacity(0.7);
        } else if (failureCount > warningCutoff) {
            color = warnColorWithOpacity(0.7);
        }

        return {
            "workflow": workflow.workflowName,
            "failureCount": failureCount,
            "failureCountColor": color,
        }
    }).sort((a, b) => a.failureCount - b.failureCount);

    const topListAvgBuildTimeWorkflows = topAvgBuildTimeWorkflows.map((workflow) => {
        const criticalCutoff = 10*60;
        const warningCutoff = 5*60;
        const avgBuildTime = Math.floor(workflow.metric);
        let color = okColorWithOpacity(0.7);
        if (avgBuildTime > criticalCutoff) {
            color = criticalColorWithOpacity(0.7);
        } else if (avgBuildTime > warningCutoff) {
            color = warnColorWithOpacity(0.7);
        }

        return {
            "workflow": workflow.workflowName,
            "avgBuildTime": avgBuildTime,
            "avgBuildTimeColor": color,
        }
    }).sort((a, b) => a.avgBuildTime - b.avgBuildTime);

    useEffect(() => {
        dispatch(initAction({integrationSlug}));
    }, []);

    const runDetailsURL = (runSlug)  => `/${org}/github/${integrationSlug}/actions/run-details/${runSlug}`;

    return (
        <React.Fragment>
            <Outlet />
            <Stack direction="column">
                <Typography variant="h5"  sx={{pt: 2}}>
                    Egress Traffic Overview
                </Typography>
                <Divider />
                
                <Stack direction="row" spacing={2} sx={{pt: 2}} 
                    justifyContent="space-around"
                    alignItems="center">    
                    <Card sx={{width: '30%', height: '100px', marginLeft: '0px!important'}}>
                        <Stack direction="row" >
                            <Box sx={{
                                    backgroundColor: dangerColor, 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: '10px 0 0 10px',
                                    borderRadius: '2px',
                                    paddingX: 1,
                                    maxWidth: '55px',
                                }}
                            >
                                <Typography variant="h4" sx={{color: '#FFF'}}>12</Typography>
                            </Box>
                            <CardContent sx={{padding: '12px', paddingBottom: "0!important"}}>
                                <Typography variant="body2" color="text.primary">
                                out of 102 workflows don't have egress monitoring enabled
                                </Typography>
                            </CardContent>
                        </Stack>
                        <CardActions sx={{justifyContent: 'flex-end'}} >
                            <Button size="small">Enable using Bolt</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{width: '30%', height: '100px', marginLeft: '0px!important'}}>
                        <Stack direction="row" >
                            <Box sx={{
                                    backgroundColor: dangerColor, 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: '10px 0 0 10px',
                                    borderRadius: '2px',
                                    paddingX: 1,
                                    maxWidth: '55px',
                                }}
                            >
                                <Typography variant="h4" sx={{color: '#FFF'}}>05</Typography>
                            </Box>
                            <CardContent sx={{padding: '12px', paddingBottom: "0!important"}}>
                                <Typography variant="body2" color="text.primary">
                                workflow jobs have non-secure http eggsress traffic
                                </Typography>
                            </CardContent>
                        </Stack>
                        <CardActions sx={{justifyContent: 'flex-end'}} >
                            <Button size="small">Restrict to HTTPS only</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{width: '30%', height: '100px', marginLeft: '0px!important'}}>
                        <Stack direction="row" >
                            <Box
                                sx={{
                                    backgroundColor: dangerColor, 
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '10px 0 0 10px',
                                    borderRadius: '2px',
                                    paddingX: 1,
                                    maxWidth: '55px',
                                }}
                            >
                                <Typography variant="h4" sx={{color: '#FFF'}}>02</Typography>
                            </Box>
                            <CardContent sx={{padding: '12px', paddingBottom: "0!important"}}>
                                <Typography variant="body2" color="text.primary">
                                egress domains are not in your trusted list
                                </Typography>
                            </CardContent>
                        </Stack>
                        <CardActions sx={{justifyContent: 'flex-end'}} >
                            <Button size="small">Manage trusted domains</Button>
                        </CardActions>
                    </Card>
                </Stack>
            </Stack>
            <Stack direction="column">
                <Typography variant="h5" sx={{pt: 2}}>
                    Workflow Run Stats
                </Typography>
                <Divider />
                <Stack direction="row" spacing={2} sx={{pt: 2}} 
                    justifyContent="space-around"
                    alignItems="center">
                    <Stack direction="column" spacing={2} sx={{width: '30%', height: '250px'}}>
                        <Typography variant="body1" sx={{pb: 2}}>
                            Build conclusions
                        </Typography>
                        <Box style={{width: '100%', height: '200px', marginTop: 0}}>
                            <ConclusionDistribution data={processConclusionDistribution(workflowConclusionDistribution)} />
                        </Box>
                    </Stack>
                    <Stack direction="column" spacing={2} sx={{width: '30%', height: '250px'}}>
                        <Typography variant="body1" sx={{pb: 2}}>
                            Top workflows by build failures
                        </Typography>
                        <Box style={{width: '100%', height: '200px', marginTop: 0}}>
                            <TopList data={topListFailingWorkflows} 
                                indexBy="workflow" 
                                keys={['failureCount']}
                            />
                        </Box>
                    </Stack>
                    <Stack direction="column" spacing={2} sx={{width: '30%', height: '250px'}}>
                        <Typography variant="body1" sx={{pb: 2}}>
                            Top workflows by avg build times
                        </Typography>
                        <Box style={{width: '100%', height: '200px', marginTop: 0}}>
                            <TopList data={topListAvgBuildTimeWorkflows}
                                indexBy="workflow" 
                                keys={['avgBuildTime']}
                            />
                        </Box>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={2} sx={{pt: 2}} 
                    justifyContent="space-around"
                    alignItems="center">
                    <Stack direction="column" spacing={2} sx={{width: '30%', height: '250px'}}>
                        <Typography variant="body1" sx={{pb: 2}}>
                            Top jobs by build failures
                        </Typography>
                        <Box style={{width: '100%', height: '200px', marginTop: 0}}>
                            <TopList data={topListFailingWorkflows} 
                                indexBy="workflow" 
                                keys={['failureCount']}
                            />
                        </Box>
                    </Stack>
                    <Stack direction="column" spacing={2} sx={{width: '30%', height: '250px'}}>
                        <Typography variant="body1" sx={{pb: 2}}>
                            Top jobs by avg build times
                        </Typography>
                        <Box style={{width: '100%', height: '200px', marginTop: 0}}>
                            <TopList data={topListAvgBuildTimeWorkflows}
                                indexBy="workflow" 
                                keys={['avgBuildTime']}
                            />
                        </Box>
                    </Stack>
                    <Stack direction="column" spacing={2} sx={{width: '30%', height: '250px'}}>
                        <Typography variant="body1" sx={{pb: 2}}>
                            Avg Processing Time
                        </Typography>
                        <Box style={{width: '100%', height: '200px', marginTop: 0}}>
                            <JobTimeAvgs data={processJobTimeAvgs(jobTimeAvgs)} />
                        </Box>
                    </Stack>
                </Stack>
                <Typography variant="h5" sx={{pt: 2}}>
                    Workflow Jobs
                </Typography>
                <Divider />
                {latestWorkflowRunJobs.length > 0
                &&
                <TreeView
                    aria-label="customized"
                    defaultExpanded={Object.keys(latestWorkflowRunJobsByRepository)}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultEndIcon={undefined}
                    sx={{ overflowX: 'hidden', py: 2 }}
                >   
                    {
                        Object.keys(latestWorkflowRunJobsByRepository).sort().map((repoID) => {
                            const jobsMap = latestWorkflowRunJobsByRepository[repoID];
                            let repoName = jobsMap[Object.keys(jobsMap)[0]][0].githubRepoName;
                            return (
                                <StyledTreeItem sx={{py: 1}} key={repoID} nodeId={repoID} label={repoName}>
                                    {
                                        Object.keys(jobsMap).sort().map((jobName) => {
                                            const jobs = jobsMap[jobName];
                                            let jobStartDateString = undefined;
                                            let dateStringChanged = false;
                                            return (
                                                <StyledTreeItem sx={{py: 0.25}} key={jobName} nodeId={jobName} label={
                                                    <React.Fragment>
                                                        <Stack direction="row" alignItems='center' sx={{py: 0.25}}>
                                                            <JobLabel>{jobName}</JobLabel>
                                                            <Stack direction="row" className={styles.runButtonContainer}>
                                                            {
                                                                jobs.map((job, jobIndex) => {
                                                                    const newJobStartDate = new Date(job.createdAt);
                                                                    const newJobStartDateString = formatDate(newJobStartDate);
                                                                     
                                                                    if (jobStartDateString === undefined){
                                                                        jobStartDateString = newJobStartDateString;
                                                                        dateStringChanged = true;
                                                                    } else if (jobStartDateString === newJobStartDateString) {
                                                                        dateStringChanged = false;
                                                                    } else {
                                                                        jobStartDateString = newJobStartDateString;
                                                                        dateStringChanged = true;
                                                                    }
                                                                    return (
                                                                        <React.Fragment>
                                                                            {
                                                                                dateStringChanged &&
                                                                                <React.Fragment>
                                                                                    {
                                                                                        jobIndex > 0 &&
                                                                                        <Divider orientation="vertical" flexItem sx={{ml: '5px', mr: '10px'}}/>
                                                                                    }
                                                                                    <Typography variant="caption" sx={{minWidth: '60px', lineHeight: '24px', opacity: 0.6}}>{jobStartDateString} &gt;&gt; </Typography>
                                                                                </React.Fragment>
                                                                            }
                                                                            <RunButton status={job.jobStatus}
                                                                                conclustion={job.jobConclusion} 
                                                                                attempts={job.runAttempt}
                                                                                to={runDetailsURL(job.jobID)}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            }
                                                            </Stack>
                                                        </Stack>
                                                    </React.Fragment>}
                                                />
                                            )
                                        })
                                    }
                                </StyledTreeItem>
                            )
                        })
                    }
                </TreeView>
                }
                {props.children}
            </Stack>
        </React.Fragment>
    )
}
