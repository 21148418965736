import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Stack } from '@mui/system';

export function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { loggedInUser, loggedInUserOrganization } = props;
  const { name, email } = loggedInUser;

  const organizationName = loggedInUserOrganization?.name;
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* <Typography sx={{ minWidth: 100}}>
            <a href={"/docs"} target={"_blank"} style={{ textDecoration: "none" }}>
                Docs <ListItemIcon><ArrowOutwardIcon/></ListItemIcon>
            </a>
        </Typography> */}
        {/* <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <Tooltip title="Account settings">
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, p: 0.5, borderRadius: 1, border: "1px solid rgba(43,82,112,0.6)" }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Typography variant="caption" sx={{color: "#333", m: "0 15px 0 5px"}}>
              {organizationName.toUpperCase()}
            </Typography>
            <Avatar sx={{ width: 24, height: 24, bgcolor: "#2b5270", fontSize: 14 }}>{name ? name[0].toUpperCase() :"?"}</Avatar>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.1))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose} sx={{bgcolor: "rgba(43,82,112,0.1)"}}>
            <ListItemIcon sx={{mr: '10px'}}>
                <Avatar sx={{ width: 32, height: 32 }}>{name ? name[0].toUpperCase() :"?"}</Avatar>
            </ListItemIcon>
            <Stack spacing={-0.5}>
                <Typography variant="subtitle1">{name}</Typography>
                <Typography variant="subtitle2">{email}</Typography>
            </Stack>
            
            
            
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={props.logoutFunc}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
