import axios from "axios";

// A mock function to mimic making an async request for data
export const fetchSessionData = async () => {
    return await axios
        .get("me")
}

export const logout = async () =>{
    return await axios
        .post("logout")
}

export const signup = async (payload) => {
    return await axios
        .post("users/signup", payload)
}

export const login = async (payload) => {
    return await axios  
        .post("login", payload)
}