import { 
  selectTab as selectTabAction, 
  fetchAccessRequestsAsync,
} from "./accessRequestsSlice";

import { startTerminalWithPath } from "../../components/terminal/terminalSlice";

import { fetchSessionDataAsync } from "../session/sessionSlice";

const createFetchAccessRequestsListener = (listenerMiddleware) => {
  listenerMiddleware.startListening({
    actionCreator: selectTabAction,
    effect: async (action, listenerApi) => {
      listenerApi.dispatch(fetchAccessRequestsAsync(action.payload));
    },
  });
}

const createWebauthnRegistrationListener = (listenerMiddleware) => {
  listenerMiddleware.startListening({
    type: "accessRequests/startWebauthnRegistration/fulfilled",
    effect: async (action, listenerApi) => {
      console.log(action)
      listenerApi.dispatch(fetchSessionDataAsync());
    },
  });
}

const createStartAccessSessionListener = (listenerMiddleware) => {
  listenerMiddleware.startListening({
    type: "accessRequests/startSessionForAccessRequest/fulfilled",
    effect: async (action, listenerApi) => {
      const path = action.payload?.access_session?.path;
      const target = action.payload?.access_session?.target;
      if(target === "webshell"){
        listenerApi.dispatch(startTerminalWithPath(path));
      } else if(target === "webpage"){
        window.open(path, "_blank");
      }
    },
  });
}

const listeners = [
  createFetchAccessRequestsListener,
  createWebauthnRegistrationListener,
  createStartAccessSessionListener,
]

export default listeners;