import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    fetchIntegrationDetails,
} from './integrationDetailsAPI';

const tabs = [
    // {label: 'Overview', slug: 'overview'},
    {label: 'Action Runs', slug: 'actions', title: 'Issues found in Actions configuration'},
    {label: 'Action Analysis', slug: 'actions-analysis', title: 'Issues found in Actions configuration'},
    {label: 'Repositories', slug: 'repositories'},
    {label: 'Deploy Keys', slug: 'deploy-keys', title: 'Issues found in Deploy Keys configuration'},
    {label: 'Access Tokens', slug: 'access-tokens', title: 'Issues found in Access Tokens configuration'},
    // {label: 'Activity', slug: 'activity', disabled: true},
].map((tab, index) => {
    return {
        ...tab,
        id: index + 1,
    }
});

const initialState = {
    tabs: tabs,
    selectedTabSlug: 'overview',

    integrationDetails: tabs.reduce((acc, tab) => {
        acc[tab.slug] = [];
        return acc;
    }, {}),
    integrationDetailsFetchStatus: 'idle',
    integrationDetailsFetchError: undefined,
}

export const fetchIntegrationDetailsAsync = createAsyncThunk(
    'dashboard/fetchIntegrationDetails',
    async (payload) => {
        const response = await fetchIntegrationDetails(payload);
        return response.data;
    }
);

export const integrationDetailsSlice = createSlice({
    name: 'integrationDetails',
    initialState,
    reducers: {
        selectTab: (state, action) => {
            const { tabSlug } = action.payload;
            state.selectedTabSlug = tabSlug;
            
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIntegrationDetailsAsync.pending, (state) => {
                state.integrationDetailsFetchStatus = 'loading';
                state.integrationDetailsFetchError = undefined;
            })
            .addCase(fetchIntegrationDetailsAsync.fulfilled, (state, action) => {
                state.integrationDetailsFetchStatus = 'idle';
                const { targetSlug, data } = action.payload;
                if (targetSlug) {
                    state.integrationDetails[targetSlug] = data;
                    state.integrationDetails[`${targetSlug}-report`] = action.payload?.report;
                }
            })
            .addCase(fetchIntegrationDetailsAsync.rejected, (state, action) => {
                state.integrationDetailsFetchStatus = 'idle';
                state.integrationDetailsFetchError = action.error.message;
            })
    }
});

export const { selectTab } = integrationDetailsSlice.actions;

export const selectIntegrationDetails = (state) => state.integrationDetails;

export default integrationDetailsSlice.reducer;