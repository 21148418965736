import React, { useEffect } from 'react';
import { useParams, Navigate, useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import  styles from './IntegrationDetails.module.css';

import { Card, Stack,
    Typography,
    CardContent,
    CardActions,
    Button,
    Box,
    Grid,
} from '@mui/material';

import {
    Verified as VerifiedIcon
 } from '@mui/icons-material';

import { VerticalTabs } from '../../components/verticalTabs/VerticalTabs';

import { selectIntegrationDetails, selectTab } from './integrationDetailsSlice';
import { Issues } from './Issues';
import { IssueConfig } from './IssueConfig';
import { Repositories } from '../repositories/Repositories';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
);

const IssueCard = function ({issue}) {
    const { org, integrationSlug } = useParams();
    const navigate = useNavigate();
    console.log(issue);
    return (
        <React.Fragment>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <b>{issue.issue_name}</b>
                </Typography>
                <Typography variant="h4" component="div">
                {issue.total_count}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Issues found in {issue.issue_name} configuration
                </Typography>
                {
                    issue.counts.slice(0,2).map((count) => { 
                        return (
                            <Typography variant="body2">
                                {bull} <b>{count.Count}</b>  {IssueConfig[count.IssueType]?.text}
                            </Typography>
                        )
                    })
                }
            </CardContent>
            <CardActions>
                <Button size="small"
                    onClick={() => navigate(`/${org}/github/${integrationSlug}/${IssueConfig[issue.counts[0].IssueType]?.slug}`)}
                >
                    See Details
                </Button>
            </CardActions>
        </React.Fragment>
    )
};

export function IntegrationDetails(props) {
    const params = useParams();
    const { org, integrationSlug } = params;
    const tabSlug = params?.tabSlug || props?.tabSlug;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        integrationDetails,
        integrationDetailsFetchStatus,
        integrationDetailsFetchError,
        tabs,
        selectedTabSlug,
    } = useSelector(selectIntegrationDetails);

    useEffect(() => {dispatch(selectTab({integrationSlug, tabSlug}))}, [tabSlug, integrationSlug]);

    if (!tabSlug) {
        return (
            <Navigate to={`/${org}/github/${integrationSlug}/actions`} />
        )
    }

    const selectedTabObj = tabs.find(t => t.slug === selectedTabSlug);
    if (!selectedTabObj || selectedTabObj?.disabled) {
        return (
            <Navigate to={`/${org}/github/${integrationSlug}/actions`} />
        )
    }

    const NoIssues = () => {
        return (
            <div className={styles.noIssues}>
                <VerifiedIcon sx={{ fontSize: 100, color: '#34b233' }} />
                <Typography variant='h5'>No issues found</Typography>
                <Typography variant='body1'>Good job! You have no issues in this section</Typography>
            </div>
        )
    }

    const Layout = (props) => (
        <Stack direction="row" spacing={0}>
            <div style={{ width: '220px', padding: '20px', height: '100%', position: 'sticky', top: '48px', boxSizing: 'border-box' }}>
                <VerticalTabs 
                    tabs={tabs} 
                    selectedTabSlug={selectedTabSlug} 
                    selectTabAction={(slug) => navigate(`/${org}/github/${integrationSlug}/${slug}`)}
                /> 
            </div>
            <div style={{ width: 'calc(100% - 220px', paddingRight: '50px', boxSizing: 'border-box'}}>
                {
                    props.children
                }
            </div>
        </Stack>
    )
 
    if (integrationDetailsFetchStatus === 'idle' && integrationDetailsFetchError === undefined
        && integrationDetails[selectedTabSlug]?.length === 0) {
            if (!(selectedTabSlug === "repositories"  || selectedTabSlug === "actions")) {
                return (
                    <Layout>
                        <NoIssues />
                    </Layout>
                )
            }
    }

    if (integrationDetailsFetchStatus !== 'idle') {
        return <Layout><div>Loading...</div></Layout>;
    }

    if (integrationDetailsFetchError !== undefined) {
        return <Layout><div>An Error has occured... Try reloading the page</div></Layout>;
    }

    console.log("selectedTabSlug",selectedTabSlug)

    // return (
    //     <Stack direction="row" spacing={0}>
    //         <div style={{ width: '220px', padding: '20px', height: '100%', position: 'sticky', top: '48px', boxSizing: 'border-box' }}>
    //             <VerticalTabs 
    //                 tabs={tabs} 
    //                 selectedTabSlug={selectedTabSlug} 
    //                 selectTabAction={(slug) => navigate(`/${org}/github/${integrationSlug}/${slug}`)}
    //             /> 
    //         </div>
    //         <div style={{ width: 'calc(100% - 220px', paddingRight: '50px', boxSizing: 'border-box'}}>
    //             <Outlet />
    //         </div>
    //     </Stack>
    // )


    return (
        <Layout>
            {
                (
                    (selectedTabSlug === "repositories"  || selectedTabSlug === "actions")
                    && props.children
                ) || (
                    selectedTabSlug === "overview" 
                    && <Grid container   
                        direction="row"
                        spacing={2}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{pt: '20px'}}
                    >
                        {
                            integrationDetails[selectedTabSlug]?.map((issue) => {
                                return (
                                    <Grid item sx={{ width: 325 }}>
                                        <Card variant="outlined" sx={{minHeight: '250px'}}>
                                            <IssueCard issue={issue}/>
                                        </Card>
                                    </Grid>   
                                )
                            })
                        }
                    </Grid>
                ) || (
                    <Issues integrationDetails={integrationDetails}
                        selectedTabSlug={selectedTabSlug}
                        tabs={tabs} />
                )
            }
        </Layout>
    );
}