import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { 
    fetchResourcesData,
    createAccessRequestForResource,
} from './resourcesAPI';

const initialState = {
    resources: [],

    tabs: [
        { id: 1, label: 'AWS', disabled: false, slug: 'aws' },
        { id: 4, label: 'K8s', disabled: true, slug: 'k8s' },
        { id: 2, label: 'GCP', disabled: true, slug: 'gcp' },
        { id: 3, label: 'Azure', disabled: true, slug: 'azure' },
    ],

    selectedTabId: 1,
};

const getSlug = (id) => {
    const tab = initialState.tabs.find(tab => tab.id === id);
    return tab.slug;
}

export const fetchResourcesAsync = createAsyncThunk(
    'resources/fetchResources',
    async (action) => {
        const slug = getSlug(action.payload);
        const response = await fetchResourcesData(slug);
        return response.data;
    }
);

export const createAccessRequestForResourceAsync = createAsyncThunk(
    'resources/createAccessRequestForResource',
    async (action) => {
        console.log(action)
        const response = await createAccessRequestForResource(action);
        return response.data;
    }
);

export const resourcesSlice = createSlice({
    name: 'resources',
    initialState,
    reducers: {
        selectTab: (state, action) => {
            state.selectedTabId = action.payload;
        }   
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchResourcesAsync.fulfilled, (state, action) => {
                state.resources = action.payload;
            })
            .addCase(createAccessRequestForResourceAsync.pending, (state, action) => {
                
            })
            .addCase(createAccessRequestForResourceAsync.fulfilled, (state, action) => {
                const { resource_id } = action.payload;
                const resource = state.resources.find(resource => resource.id === resource_id);
                resource.access_request = action.payload;
            })
    }
});

export const { selectTab } = resourcesSlice.actions;

export const selectResources = (state) => state.resources;

export default resourcesSlice.reducer;