/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RepositoryDetails
*/

import React, { useEffect } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import  styles from './RepositoryDetails.module.css';

import { selectRepositoryDetails, init as initAction } from './repositoryDetailsSlice';

import { 
    Stack,
    Typography,
    Divider,
    Drawer,
    Alert,
    AlertTitle,
    Collapse,
} from '@mui/material';

import {
    ArrowDownward,
    Verified as VerifiedIcon,
    Close as CloseIcon,
    ArrowOutward as ArrowOutwardIcon,
 } from '@mui/icons-material';

import { RepositoryScore } from '../../components/repositoryScore/RepositoryScore';
import { RepositoryChips } from '../../components/repositoryChips/RepositoryChips';
import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';
import { Dependabot } from './Dependabot';
import { SecretScanning } from './SecretScanning';
import { Protection } from './Protection';

import { openLinkInNewTab } from '../../components/constants/Constants';
import { CodeScanning } from './CodeScanning';
import { DeployKey } from './DeployKey';
import { Workflow } from './Workflow';
import { Action } from './Action';

export function RepositoryDetails(props) {
    const { org, integrationSlug, repositorySlug } = useParams()
    const params = useParams;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { details, detailsFetchStatus } = useSelector(selectRepositoryDetails);

    const repositoryDetails = details[repositorySlug];
    const fullName = repositoryDetails?.full_name;
    const dependabotDetails = repositoryDetails?.dependabot;
    const branchProtection = repositoryDetails?.branch_protection;
    const secretScanning = repositoryDetails?.secret_scanning;
    const codeScanning = repositoryDetails?.code_scanning;
    const deployKey = repositoryDetails?.deploy_key;
    const deployKeyCount = repositoryDetails?.deploy_key_count;
    const workflow = repositoryDetails?.workflow;
    const workflowCount = repositoryDetails?.workflow_count;
    const actions = repositoryDetails?.actions;
    const codeowner_protects_workflows = repositoryDetails?.codeowner_protects_workflows;
        
    const githubUrl = `https://github.com/${fullName}`;

    const basePath = `/${org}/github/${integrationSlug}/repositories`;

    useEffect(() => {
        dispatch(initAction({integrationSlug, repositorySlug}));
    }, [repositorySlug, integrationSlug]);

    const repository = details[repositorySlug];

    const Layout = (props) => {
        return (
            <Drawer anchor="right"
                open={repositorySlug !== undefined}
                SlideProps={{direction: "left"}}
                onClose={() => navigate(basePath)}>
                <Stack direction="column" className={styles.container}>
                    {props.children}
                </Stack>
            </Drawer>
        )
    }
    let content = null;

    if(detailsFetchStatus === 'loading') {
        content = (
            <Typography>
                Loading...
            </Typography>
        )
    } else if (repository) {
        content = (
            <React.Fragment>
                <Stack direction="column"  className={styles.header}>
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                        <Stack direction="row" alignItems="flex-start">
                            <RepositoryScore value={repository.score} />
                            <Typography sx={{ml: 1}} variant="h6">
                                {repository.name}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                            <ArrowOutwardIcon sx={{cursor: "pointer", translate: "0 6px"}}
                                onClick={() => openLinkInNewTab(githubUrl)} />
                            <CloseIcon sx={{cursor: "pointer", translate: "0 6px"}}
                                onClick={() => navigate(basePath)} />
                        </Stack>
                    </Stack>
                    <Divider sx={{mb: 0}} />
                </Stack>
                <RepositoryChips sx={{mb: 1}} summary={repository.posture_summary} />
                <Workflow workflow={workflow} count={workflowCount} codeownerProtection={codeowner_protects_workflows} />
                <Action actions={actions} />
                <Protection branchProtection={branchProtection} githubUrl={githubUrl}/>
                <Dependabot dependabot={dependabotDetails} />
                <DeployKey deployKey={deployKey} count={deployKeyCount} />
                <SecretScanning secretScanning={secretScanning}/>
                <CodeScanning codeScanning={codeScanning} />
            </React.Fragment>
        )
    }

    return (
        <Layout>
            {content}
        </Layout>
    )
}
