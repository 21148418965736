import React from "react";

import {
    Button,
    Typography,
    Stack,
    Table,
    TableBody,
    TableCell,
    Divider,
} from '@mui/material';

import {
    Verified as VerifiedIcon,
    OpenInNew as OpenInNewIcon,
    Info as InfoIcon,
} from '@mui/icons-material';

import { StyledTableRow } from '../../components/styledTableRow/StyledTableRow';

import { CollapsibleContainer } from '../../components/collapsibleContainer/CollapsibleContainer';

import {
    infoColor,
    openLinkInNewTab,
} from '../../components/constants/Constants';
import { CheckList } from "../../components/checkList/CheckList";

const IssueTitle = ({issues, children}) => {
    return (
      <React.Fragment>
        {children} - {`${issues.length} Issue${issues.length > 1 ? 's' : ''} found`} 
      </React.Fragment>
    )
}

const workflowConfig = {
    "KoalaLab/Github/ActionIssue/ThirdPartyActionWithoutHash": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Third party actions without hash`}
            </IssueTitle>
        },
        iconColor: 'error',
        text: "3rd party actions without hash.",
        slug: "actions",
    },
    "KoalaLab/Github/ActionIssue/UnsecureCommandsAllowed": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Unsecure commands allowed using ALLOW_UNSECURE_COMMANDS flag`}
            </IssueTitle>
        },
        iconColor: 'error',
        slug: "actions",
        text: "Unsecure commands allowed using ALLOW_UNSECURE_COMMANDS flag.",
    },
    "KoalaLab/Github/ActionIssue/PullRequestTargetEventTrigger": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Pull_request_target event trigger found in actions`}
            </IssueTitle>
        },
        iconColor: 'error',
        slug: "actions",
        text: "pull_request_target event trigger found in actions.",
    },
    "KoalaLab/Github/ActionIssue/DangerousPermissions": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Actions with dangerous permissions`}
            </IssueTitle>
        },
        iconColor: 'error',
        slug: "actions",
        text: "Actions with dangerous permissions.",
    },
    "KoalaLab/Github/ActionIssue/ExpressionInjections": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Actions with expression injections vulnerability`}
            </IssueTitle>
        },
        iconColor: 'error',
        slug: "actions",
        text: "Actions with expression injections vulnerability.",
    },
    "KoalaLab/Github/ActionIssue/CreateOrApprovePR": {
        sectionHeader: {
          title: (issues) =>
            <IssueTitle issues={issues}>
              {`Actions that create or approve Pull Requests`}
            </IssueTitle>
        },
        iconColor: 'error',
        slug: "actions",
        text: "Actions that create or approve Pull Requests.",
    }
}

export function Workflow(props) {
    const { workflow: workflowIssues, count, codeownerProtection } = props;

    const workflowIssuesMap = workflowIssues.reduce((acc, issue) => {
        acc[issue.type] = acc[issue.type] || [];
        acc[issue.type].push(issue);
        return acc;
      }, {})

    return (
        <CollapsibleContainer title="Workflows">
            <Stack sx={{px: 2, pb: 2}}>
                {
                    count === 0
                    &&   
                    <Stack direction="column"  sx={{mt: 2}}>
                        <Stack direction="column" alignItems="center" 
                            sx={{borderRadius: 1, bgcolor: 'rgb(240 248 255)', p: 1 }}>
                            <InfoIcon sx={{ fontSize: 24, color: infoColor }} />
                            <Typography variant="body2" 
                                sx={{mt: 1, textAlign: 'center', px: 3, color: 'rgb(1, 67, 97)'}}>
                                You don't have any workflows defined in this Github repository.
                            </Typography>
                        </Stack>
                    </Stack>
                }
                {
                    count > 0 
                    &&
                    <CheckList list={[
                        {
                            label: codeownerProtection
                                ? "Codeowners protection is enabled for workflows in this repository."
                                : "Codeowners protection is not enabled for workflows in this repository.",
                            checked: codeownerProtection,
                            level: codeownerProtection ? "info" : "danger",
                        }
                    ]}/>
                }
                {
                    workflowIssues.length === 0 && count > 0
                    &&
                    <Stack direction="column" sx={{mt: 2}}>
                        <Stack direction="column" alignItems="center" 
                            sx={{borderRadius: 1, bgcolor: 'rgb(240 248 255)', p: 1 }}>
                            <VerifiedIcon sx={{ fontSize: 24, color: '#34b233' }} />
                            <Typography variant="body2" 
                                sx={{mt: 1, textAlign: 'center', px: 3, color: 'rgb(1, 67, 97)'}}>
                                Great!! No Issues found in your Workflows.
                            </Typography>
                        </Stack>
                    </Stack>
                }
                {
                    Object.keys(workflowIssuesMap).map(issueType => {
                        const { sectionHeader, iconColor, slug, text } = workflowConfig[issueType];
                        const issues = workflowIssuesMap[issueType];
                        return (
                            <Stack direction="column" sx={{mt: 2}}>
                                <Typography variant="body2" sx={{mt: 1}}>
                                    {sectionHeader.title(issues)}
                                </Typography>
                                <Divider />
                                <Table size="small">
                                    <TableBody>
                                        {
                                            issues.map((issue) => {
                                                return (
                                                    <StyledTableRow key={issue.id} sx={{height: '10px'}}>
                                                        <TableCell >
                                                            <Typography >
                                                                {issue.action?.name}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell sx={{width: '141px'}} >
                                                            <Button variant="text"
                                                                size="small"
                                                                href={issue.githubLink}
                                                                target="_blank"
                                                                endIcon={<OpenInNewIcon />}
                                                            >
                                                                View on Github
                                                            </Button>
                                                        </TableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Stack>
                        )
                    })
                }
            </Stack>
        </CollapsibleContainer>
    )
}