/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RunDetails
*/

import axios from "axios";

export const fetchDetails = async ({ integrationSlug, jobSlug }) => {
    return await axios
        .get(`github/integrations/${integrationSlug}/workflow-jobs/${jobSlug}`)
}
