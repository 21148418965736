/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features RepositoryDetails
*/

import axios from "axios";

export const fetchDetails = async ({integrationSlug, repositorySlug}) => {
    return await axios
        .get(`/github/integrations/${integrationSlug}/repositories/${repositorySlug}`)
}
