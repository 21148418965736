import React, { useRef } from 'react';

import  styles from './IntegrationDetails.module.css';

import {
    Typography,
    Divider, 
    Table,
    TableBody,
    TableContainer,
    Stack,
    TableRow,
    TableHead,
    TableCell
} from '@mui/material';

import {
  criticalColorWithOpacity,
  dangerColor,
  infoColor,
  okColor,
  okColorWithOpacity,
  warnColor,
  warnColorWithOpacity,
} from '../../components/constants/Constants';

import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor';

import {
  DisabledByDefault as DisabledByDefaultIcon,
  CheckBox as CheckBoxIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  Comment as CommentIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';

import { RowComponents } from './RowComponents';
import { IssueConfig } from './IssueConfig';

const indexJS = `const core = require('@actions/core');
const exec = require('child_process').exec;

async function run() {
    //const path = "test/test.properties";
    const path = core.getInput('path');
    console.log(\`path:\${path}\`);

    //const property = "version";
    const property = core.getInput('property');
    console.log(\`property:\${property}\`);

    //const value = "1.0.1";
    const value = core.getInput('value');
    console.log(\`value:\${value}\`);

    exec(\`grep -r "^[#]*\s*\${property}=.*" "\${path}"\`, (grepError) => {
        if(grepError != null) {
            console.log("Property doesnt exist, trying to insert");
            //append to end of file.
            exec(\`cat >> "\${path}" <<EOF 
            \\n\${property}=\${value}\`, (error, stderr) => {
                if(error != null) {
                    core.setFailed(error);
                }
                if(stderr != null) {
                    console.log(stderr);
                }
            });
        } else {
            exec(\`sed -ir "s/^[#]*\s*\${property}=.*/\${property}=\${value}/" "\${path}"\`, (error, stderr) => {
                if(error != null) {
                    core.setFailed(error);
                }
                if(stderr != null) {
                    console.log(stderr);
                }
            });
        }
    });
}

run();
`

const IssuesTable = ({rowComponent, issues}) => {
    const RowComponent = rowComponent;
    return (
      <TableContainer sx={{margin: '0 0 40px 0'}} >
        <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>
            {issues.map((issue) => (
              <RowComponent key={issue.id} issue={issue} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
}

export function Issues(props) {
    const editorRef = useRef(null);

    const {selectedTabSlug, integrationDetails, tabs} = props;

    let issues = integrationDetails[selectedTabSlug];

    const selectedTabObj = tabs.find((tab) => tab.slug === selectedTabSlug);

    const reports = integrationDetails[`${selectedTabSlug}-report`];

    const taintedReport = reports?.find((report) => report.analysis?.results?.length > 0)

    const handleEditorDidMount = (editor, monaco) => {
      // Save a reference to the editor instance
      editorRef.current = editor;
      // Specify the range of lines you want to collapse
      const startLineNumber = 5; // The line to start folding
      const endLineNumber = 10; // The line to end folding

      monaco.languages.registerFoldingRangeProvider("javascript", {
        provideFoldingRanges: function (model, context, token) {
          return [
            // comment1
            {
              start: 1,
              end: 5,
              kind: monaco.languages.FoldingRangeKind.Comment,
              forceCollapse: true,
            },
            // comment2
            {
              start: 7,
              end: 16,
              kind: monaco.languages.FoldingRangeKind.Comment,
            },
            // foldable text
            {
              start: 18,
              end: 20,
            },
            // region1
            {
              start: 23,
              end: 30,
              kind: monaco.languages.FoldingRangeKind.Region,
            },
            // region2
            {
              start: 32,
              end: 44,
              kind: monaco.languages.FoldingRangeKind.Region,
            },
          ];
        },
      });
      // Create a range to fold
      const foldingRange = new monaco.Range(2, 1, 5, 1); // Example range

      // Fold the range
      editor.deltaDecorations([], [
        {
          range: foldingRange,
          options: {
            isWholeLine: true,
            inlineClassName: 'folded-range', // Custom CSS class for styling the folded range
            glyphMarginClassName: 'fold-icon', // CSS class for the glyph margin icon
          },
        },
      ]);
      console.log(editor)
      let visibleLines = [];
      if(editor && taintedReport) {
        const ranges = taintedReport.analysis.results.map((result) => {
          const { start, extra, end } = result;
          const { start: sourceStart, end: sourceEnd } = extra.dataflow_trace.taint_source[1][0];
          visibleLines.push(start.line, end.line, sourceStart.line, sourceEnd.line);
          return [
            {
              range: new monaco.Range(start.line, start.col, end.line, end.col),
              options: { inlineClassName: styles.myInlineDecoration },
            },
            {
              range: new monaco.Range(sourceStart.line, sourceStart.col, sourceEnd.line, sourceEnd.col),
              options: { inlineClassName: styles.myInlineDecoration },
            },
          ]}).flat();

        console.log(ranges)
        console.log(editor)

        visibleLines = visibleLines.filter((item,
          index) => visibleLines.indexOf(item) === index).sort((a, b) => a - b);
        console.log(visibleLines)

        editor.createDecorationsCollection(ranges);
      }
    }
    
    issues = issues.reduce((acc, issue) => {
      acc[issue.type] = acc[issue.type] || [];
      acc[issue.type].push(issue);
      return acc;
    }, {})

    return (
        <div style={{marginTop: '20px'}}>
            {
              selectedTabSlug === "actions-analysis"
              &&
              <React.Fragment>
                <Typography variant='h5'>Third Party Actions</Typography>
                <Divider sx={{mb: 1}}/>
              </React.Fragment>
            }
            
            {
              selectedTabSlug === "actions-analysis"
              &&
              <TableContainer sx={{margin: '0 0 40px 0'}} >
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                      <TableCell >
                          <Typography>Owner</Typography>
                      </TableCell>
                      <TableCell >
                          <Typography>Repo</Typography>
                      </TableCell>
                      <TableCell >
                          <Typography>Stars</Typography>
                      </TableCell>
                      <TableCell >
                          <Typography>Version</Typography>
                      </TableCell>
                      <TableCell >
                          <Typography>Latest Release</Typography>
                      </TableCell>
                      <TableCell align='center' >
                          <Typography>Taint Analysis</Typography>
                      </TableCell>
                  </TableHead>
                  <TableBody>
                    {
                      reports.map((report) => (
                        <React.Fragment>
                          <TableRow key={report.id} >
                            <TableCell >
                                <Typography>{report.owner}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>{report.repo}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>{report.stars}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>{report.ref}</Typography>
                            </TableCell>
                            <TableCell >
                                <Typography>{report.latest_release}</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography>
                                    {
                                      report.analysis?.results?.length > 0 
                                      ? <DisabledByDefaultIcon sx={{color: dangerColor}} />  
                                      : <CheckBoxIcon sx={{color: okColor}} />
                                    } 
                                </Typography>
                            </TableCell>
                          </TableRow>
                          {
                            report.analysis?.results?.length > 0 
                            &&
                            <TableRow>
                              <TableCell colSpan={6} sx={{height: 10}}>
                                <Editor height="250px" 
                                  options={{
                                    readOnly: true,
                                    minimap: {
                                      enabled: false,
                                    },
                                  }}
                                  onMount={handleEditorDidMount}
                                  defaultLanguage="javascript" 
                                  defaultValue={indexJS} />
                              </TableCell>
                            </TableRow>
                          }
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            <Typography variant='h5'>{selectedTabObj?.title}</Typography>
            <Divider sx={{mb: 5}}/>
            {
                Object.keys(issues).map((issueType) => {
                    return (
                      IssueConfig[issueType] &&
                      RowComponents[issueType] &&
                      <div key={issueType}>
                        {
                          IssueConfig[issueType].sectionHeader &&
                          <div>
                            <Stack direction={'row'}>
                              <ErrorOutlineIcon sx={{height: '24px'}} 
                                color={IssueConfig[issueType]?.iconColor}
                                fontSize='small' />
                              <div className={styles.sectionHeaderContainer}>
                                <Typography color="textPrimary" className={styles.title} variant='body1'>
                                    {IssueConfig[issueType].sectionHeader.title(issues[issueType])}
                                </Typography> 
                              </div>
                            </Stack>
                            <Divider />
                          </div>
                        }
                        <IssuesTable rowComponent={RowComponents[issueType]} 
                          issues={issues[issueType]} /> 
                      </div>
                    )
                })
            }

        </div>
    )
}