/*
    Generated using Simple-Scaffold
    npx simple-scaffold@latest \
        -t templates/feature -o src/features Repositories

    TODO: Add the following lines in src/app/store.js:
    import repositoriesListenerCreators from '../features/repositories/repositoriesListeners';
    repositoriesListenerCreators.forEach(listener => listener(listenerMiddleware));
*/

import {
    init as initAction,
    fetchDetailsAsync,
    setFilter,
} from './repositoriesSlice';

const createInitListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: initAction,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchDetailsAsync(action.payload));
        },
    });
}

const createFilterChangeListener = (listenerMiddleware) => {
    listenerMiddleware.startListening({
        actionCreator: setFilter,
        effect: async (action, listenerApi) => {
            listenerApi.dispatch(fetchDetailsAsync(action.payload));
        },
    });
}

const listeners = [
    createInitListener,
    createFilterChangeListener,
]

export default listeners;